import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../../Redux/actions/authActions'
import logo from '../../assets/pantrylogo.png'
import { Form, Icon, Input, TreeSelect, Select, notification, Skeleton, Switch, Modal, Button, message } from 'antd';
import axios from 'axios'
import Footer from '../../Components/Footer'
import SubmitButton from '../../Components/SubmitButton'
import { serverUrl } from '../../Config/api'

const { Option } = Select
const { SHOW_PARENT } = TreeSelect;
var title = "Error"

class PantrySetting extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disable: false,
            visible: false,
            questionsData: [],
            showAuth: true,
            isData: true,
            groceryData: [],
            groceryArr: [],
            selectedData: [],
            allUsers: [],
            currentEmail: '',
            showPassword: false,
            userPassword: '',
            loading1: false,
            topicsData: [],
            allData: [],
            topic: {}
        }
    }

    async componentDidMount() {
        let { topicsData } = this.state
        const { user } = this.props
        if (!user) {
            this.props.history.replace('/login')
        }

        axios.post(`${serverUrl}/consumer/post/get-auth`, user)
            .then((result) => {
                const { users } = result.data
                this.setState({ allUsers: users })
            })
        await axios.get(`${serverUrl}/admin/get/product-by-country/${user.country}`)
            .then((result) => {
                const { data, success } = result.data
                if (success) {
                    for (var i in data) {
                        topicsData.push(data[i].topic)
                    }
                    var resArr = [];
                    topicsData.forEach((item) => {
                        var i = resArr.findIndex(x => x.name === item.name);
                        if (i <= -1) {
                            resArr.push(item);
                        }
                    });
                    this.setState({ isData: topicsData.length, topicsData: resArr, allData: data }, async () => {
                        const { allData } = this.state
                        const { user, form } = this.props
                        const { setFieldsValue } = form
                        await axios.get(`${serverUrl}/consumer/get/pantry/${user._id}`)
                            .then((res) => {
                                const { data } = res
                                if (data.success) {
                                    const { result } = data
                                    var groc = []
                                    var groceryData = []
                                    for (var j in allData) {
                                        if (result.topic.name === allData[j].topic.name) {
                                            groc.push(allData[j])
                                        }
                                    }

                                    for (var i in groc) {
                                        var obj = {
                                            title: groc[i].groceryName,
                                            value: i,
                                            key: i,
                                            buId: groc[i].buId,
                                            children: []
                                        }
                                        var { excelFileObj } = groc[i]
                                        var count = 0
                                        for (var j in excelFileObj) {
                                            if (j !== "null") {
                                                obj.children.push({
                                                    title: j,
                                                    value: `${i}-${count}`,
                                                    key: `${i}-${count}`,
                                                    children: []
                                                })
                                                var childCount = 0
                                                for (var k in excelFileObj[j]) {
                                                    if (k !== "null") {
                                                        obj.children[count].children.push({
                                                            title: k,
                                                            value: `${i}-${count}-${childCount}`,
                                                            key: `${i}-${count}-${childCount}`,
                                                            children: []
                                                        })
                                                        for (var l in excelFileObj[j][k]) {
                                                            obj.children[count].children[childCount].children.push({
                                                                title: excelFileObj[j][k][l]['product_title'],
                                                                value: `${i}-${count}-${childCount}-${l}`,
                                                                key: `${i}-${count}-${childCount}-${l}`,
                                                                data: excelFileObj[j][k][l]
                                                            })
                                                        }
                                                        childCount++
                                                    }
                                                }
                                                count++
                                            }
                                        }
                                        groceryData.push(obj)
                                    }
                                    this.setState({ groceryData, groceryArr: groc, topic: result.topic, showAuth: result.sharePantry, selectedData: result.orderOfData })
                                    setFieldsValue(result)
                                }
                            })
                    })
                }
            })
    }

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    handleSubmit = e => {
        const { selectedData, topic } = this.state
        const { user, form } = this.props
        const { validateFields } = form
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                this.setState({ disable: true, loading: true })
                values.userId = user._id
                values.country = user.country
                values.topicName = topic.name
                values.topic = topic
                values.orderOfData = selectedData
                axios.post(`${serverUrl}/consumer/post/add-pantry`, values)
                    .then((result) => {
                        if (result.data.success) {
                            this.openNotification('Success', result.data.message, 'check')
                            this.setState({ disable: false, loading: false })
                            setTimeout(() => {
                                window.location.reload()
                            }, 300)
                        }
                        else {
                            this.openNotification(title, result.data.message, 'close-circle', 'red')
                            this.setState({ disable: false, loading: false })
                        }
                    })
                    .catch((err) => {
                        this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                        this.setState({ disable: false, loading: false })
                    })
            }
        });
    };



    checkLimit(name) {
        const { getFieldValue, setFieldsValue } = this.props.form
        if (getFieldValue(name).length > 4) {
            this.openNotification('Error', 'You can only select Maximun 4!!!', 'close-circle', 'red')
            setFieldsValue({
                [name]: getFieldValue(name).slice(0, 4)
            })

            return false
        }

        return true
    }

    updateChild = e => {
        const { groceryData } = this.state
        let myData = []
        for (var v of e) {
            for (var i of groceryData) {
                if (i.value === v.charAt(0)) {
                    var data = myData.filter(d => d.value === v.charAt(0))
                    if (!data.length) {
                        myData.push({
                            title: i.title,
                            value: i.value,
                            key: i.key,
                            imageLink: i.imageLink,
                            buId: i.buId,
                            children: []
                        })
                    }
                    const { children } = i
                    for (var j of children) {
                        if (j.value === v.slice(0, 3)) {
                            var data1 = myData.map((d, index) => {
                                if (d.value === v.charAt(0)) {
                                    return index
                                }
                            })
                            var key = data1.filter(d => d !== undefined)[0]
                            var productGroup = myData[key].children.filter(d => d.value === j.value)
                            if (!productGroup.length) {
                                myData[key].children.push({
                                    title: j.title,
                                    value: j.value,
                                    key: j.key,
                                    children: []
                                })
                            }
                            var groupChild = j.children
                            for (var k of groupChild) {
                                if (k.value === v.slice(0, 5)) {
                                    var data2 = myData[key].children.map((d, ind) => {
                                        if (d.value === v.slice(0, 3)) {
                                            return ind
                                        }
                                    })
                                    var key1 = data2.filter(d => d !== undefined)[0]
                                    var productType = myData[key].children[key1].children.filter(d => d.value === k.value)
                                    if (!productType.length) {
                                        myData[key].children[key1].children.push({
                                            title: k.title,
                                            value: k.value,
                                            key: k.key,
                                            children: []
                                        })
                                    }
                                    var titleChild = k.children
                                    for (var l of titleChild) {
                                        if (l.value === v) {
                                            var data3 = myData[key].children[key1].children.map((d, ind) => {
                                                if (d.value === v.slice(0, 5)) {
                                                    return ind
                                                }
                                            })

                                            var key2 = data3.filter(d => d !== undefined)[0]
                                            var productTitle = myData[key].children[key1].children[key2].children.filter(d => d.value === k.value)
                                            if (!productTitle.length) {
                                                myData[key].children[key1].children[key2].children.push({
                                                    title: l.title,
                                                    value: l.value,
                                                    key: l.key,
                                                    data: l.data
                                                })
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
        this.setState({ selectedData: myData })
    }

    showModal(e) {
        this.setState({ showPassword: true, currentEmail: e })
    }

    handleOk = () => {
        const { currentEmail, userPassword } = this.state
        const obj = {
            _id: currentEmail,
            password: userPassword
        }
        this.setState({ loading1: true }, () => {
            axios.post(`${serverUrl}/consumer/user/login-id`, obj)
                .then((result) => {
                    const { data } = result
                    if (data.success) {
                        message.success('Succesfully Added!!!')
                        this.setState({ showPassword: false, currentEmail: '', loading1: false, userPassword: '' })
                    }
                    else {
                        message.warning('Oops incorrect Password')
                        this.setState({ loading1: false })
                    }
                })
        })
    }

    updateOrderOfProducts = e => {
        const { topicsData, allData } = this.state
        const { user } = this.props
        var groc = []
        var groceryData = []
        var topic = topicsData.filter(v => v._id === e)[0]

        axios.post(`${serverUrl}/consumer/post/pantry-by-topic`, {
            id: user._id,
            topicId: e
        })
            .then((result) => {
                const { data } = result
                const { setFieldsValue, resetFields } = this.props.form
                if (data.success) {
                    this.setState({ showAuth: data.result.sharePantry })
                    setFieldsValue(data.result)
                }
                else {
                    resetFields(['orderOfProducts'])
                }

                for (var j in allData) {
                    if (topic.name === allData[j].topic.name) {
                        groc.push(allData[j])
                    }
                }


                for (var i in groc) {
                    var obj = {
                        title: groc[i].groceryName,
                        value: i,
                        key: i,
                        imageLink: groc[i].imageLink,
                        buId: groc[i].buId,
                        children: []
                    }
                    var { excelFileObj } = groc[i]
                    var count = 0
                    for (var j in excelFileObj) {
                        if (j !== "null") {
                            obj.children.push({
                                title: j,
                                value: `${i}-${count}`,
                                key: `${i}-${count}`,
                                children: []
                            })
                            var childCount = 0
                            for (var k in excelFileObj[j]) {
                                if (k !== "null") {
                                    obj.children[count].children.push({
                                        title: k,
                                        value: `${i}-${count}-${childCount}`,
                                        key: `${i}-${count}-${childCount}`,
                                        children: []
                                    })
                                    for (var l in excelFileObj[j][k]) {
                                        obj.children[count].children[childCount].children.push({
                                            title: excelFileObj[j][k][l]['product_title'],
                                            value: `${i}-${count}-${childCount}-${l}`,
                                            key: `${i}-${count}-${childCount}-${l}`,
                                            data: excelFileObj[j][k][l]
                                        })
                                    }
                                    childCount++
                                }
                            }
                            count++
                        }
                    }
                    groceryData.push(obj)
                }
                this.setState({ groceryData, groceryArr: groc, topic })
            })
    }

    render() {
        const { getFieldDecorator, resetFields } = this.props.form;
        let { loading, disable, showAuth, groceryData, isData, allUsers, showPassword, topicsData, userPassword, loading1 } = this.state;

        return (
            <div>
                <div className="advertisement2">
                </div>

                <div className="header bg-light">
                    <header className="header bg-light">
                        <nav className="navbar  navbar-light">
                            <div className="container">
                                <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="collapsingNavbar">
                                    <ul className="navbar-nav ml-auto">

                                        <li className="nav-item"><Link className="nav-link" to="/">Home</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Settings
                                                </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <Link className="dropdown-item" to="/brochure-setting">Brochure settings</Link>
                                                <Link className="dropdown-item" to="/shopping-setting">ShoppingList settings</Link>
                                                <Link className="dropdown-item" to="/1to1-setting">1 To 1 Offer settings</Link>
                                                <Link className="dropdown-item" to="/notification-setting">Notifcations settings</Link>
                                            </div>
                                        </li>
                                        <li className="nav-item"><Link className="nav-link" to="/pantry">Pantry</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/brochure">Brochure</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/shoppinglist">ShoppingList</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/1to1">1 To 1 Offer</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </header>
                </div>
                <div className="main-content-bg2">
                    <section>

                        <div className="center1">
                            <div className="card2">
                                {topicsData.length ?
                                    <Form
                                        className="login-form form-w"
                                        hideRequiredMark={true}
                                        onSubmit={this.handleSubmit}
                                        encType="multipart/form-data">
                                        <h1 className="heading1" >Pantry Settings</h1>
                                        <Form.Item
                                            label="Topic"
                                        >
                                            {getFieldDecorator('topicId', {
                                                rules: [{ required: true, message: 'Please Select Topic!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    style={{ backgroundColor: '#fff' }}
                                                    placeholder="Select Topic"
                                                    optionFilterProp="children"
                                                    onChange={this.updateOrderOfProducts}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        topicsData.map((v, i) => {
                                                            return <Option value={v._id} key={i}>{v.name}</Option>
                                                        })
                                                    }
                                                </Select>,
                                            )}
                                        </Form.Item>
                                        <Form.Item
                                            label="Order of Products"
                                        >
                                            {getFieldDecorator('orderOfProducts', {
                                                rules: [{ required: true, message: 'Please Select products!' }],
                                            })(
                                                <TreeSelect
                                                    treeData={groceryData.length ? groceryData : null}
                                                    placeholder="Select Products in order you want to display!!!"
                                                    treeCheckable={true}
                                                    allowClear={true}
                                                    showArrow={true}
                                                    maxTagCount={20}
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    onChange={this.updateChild}
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                />,
                                            )}
                                        </Form.Item>
                                        <Form.Item
                                            label="Share Pantry"
                                        >
                                            {getFieldDecorator('sharePantry', {
                                                initialValue: true,
                                                rules: [{ required: true, message: 'Please Select Order of Productgroups!' }],
                                            })(
                                                <Switch checked={showAuth} onChange={(e) => this.setState({ showAuth: e })} />,
                                            )}
                                        </Form.Item>
                                        {showAuth ? <Form.Item
                                            label="Authorize"
                                        >
                                            {getFieldDecorator('authorize', {
                                                rules: [{ required: true, message: 'Please Select Authorize!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    showArrow
                                                    style={{ backgroundColor: '#fff' }}
                                                    placeholder="Select Authorize"
                                                    optionFilterProp="children"
                                                    onSelect={e => this.showModal(e)}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        allUsers.map((v, i) => {
                                                            return <Option value={v._id} key={i}>{v.userName}</Option>
                                                        })
                                                    }
                                                </Select>,
                                            )}
                                        </Form.Item> : null}
                                        <Form.Item>
                                            <SubmitButton loading={loading} disable={disable} />
                                        </Form.Item>
                                    </Form> : isData ? <Skeleton active paragraph={{ rows: 10 }} /> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 200, paddingBottom: 200 }}>
                                        <h1>OOPS No Data Found!!!</h1>
                                    </div>}
                            </div>
                        </div>
                    </section>
                    {showPassword ? <Modal
                        visible={showPassword}
                        title="Input Password"
                        onOk={this.handleOk}
                        onCancel={() => this.setState({ showPassword: false }, () => {
                            resetFields('authorize')
                        })}
                        footer={[
                            <Button key="back" onClick={() => this.setState({ showPassword: false }, () => {
                                resetFields('authorize')
                            })}>
                                Cancel
                            </Button>,
                            <Button key="submit" type="primary" loading={loading1} onClick={this.handleOk}>
                                Submit
                            </Button>,
                        ]}
                    >
                        <Input.Password
                            placeholder="Please Input User's Password"
                            value={userPassword}
                            onChange={(e) => this.setState({ userPassword: e.target.value })}
                        />
                    </Modal> : null}

                    <Footer {...this.props} />
                </div>
            </div>
        )
    }
}

const PantrySettingForm = Form.create({ name: 'normal_login' })(PantrySetting);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(PantrySettingForm)