import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser, promotionAds } from '../../Redux/actions/authActions'
import logo from '../../assets/pantrylogo.png'
import { Icon, List, Button, notification, Checkbox, Skeleton, Tabs, Popconfirm, message, Input, Badge, Modal, Switch, Descriptions } from 'antd'
import axios from 'axios'
import { AddShopping } from '../../Components'
import Footer from '../../Components/Footer'
import moment from 'moment'
import { serverUrl } from '../../Config/api'

const { TabPane } = Tabs
const title = "Error"

function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2")
}


class ShoppingList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disable: false,
            visible: false,
            questionsData: [],
            productsData: {},
            isData: false,
            loadData: true,
            shoppinData: [],
            allTopics: [],
            orderOfData: [],
            productData: [],
            currentProduct: [],
            showAdd: false,
            topicsData: [],
            showSearch: false,
            selectedData: [],
            currentTopic: [],
            currentData: [],
            groceryData: [],
            quantity: {},
            loadingData: false,
            totalPrice: {},
            isAds: true,
            buyLoading: false,
            saveLoading: false,
            showModal: false,
            inRegion: false,
            modalData: [],
            savings: 0,
            values: {},
            deliverBtn: false
        }
    }

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    }

    async componentDidMount() {
        let { topicsData } = this.state
        const { user, promotionAds, loginUser } = this.props
        if (!user) {
            return this.props.history.replace('/login')
        }
        axios.get(`${serverUrl}/consumer/get/get-user/${user._id}`)
            .then((res) => {
                const { data } = res
                loginUser(data.user)
            })
            .catch((e) => console.log(e))
        axios.get(`${serverUrl}/consumer/get/get-all-shoppinglist/${user._id}`)
            .then((res) => {
                var { result, success } = res.data
                var allTopics = []
                if (success) {
                    for (var i in result) {
                        var { topicId, topicName } = result[i]
                        allTopics.push({
                            topicId,
                            topicName
                        })
                    }
                    let { orderOfData } = result[0]
                    axios.get(`${serverUrl}/admin/get/product-by-country/${user.country}`)
                        .then((response) => {
                            var obj = {}
                            let { success, data } = response.data
                            if (success) {
                                for (var i of data) {
                                    obj[i.groceryName] = i['excelFileObj']
                                }

                                for (var i in data) {
                                    topicsData.push(data[i].topic)
                                }
                                var resArr = [];
                                topicsData.forEach((item) => {
                                    var i = resArr.findIndex(x => x.name === item.name);
                                    if (i <= -1) {
                                        resArr.push(item);
                                    }
                                });
                                this.setState({ topicsData, currentTopic: allTopics[0], currentData: result[0] })
                            }
                            this.setState({ shoppinData: result, isData: true, loadData: false, allTopics, productData: data, orderOfData })
                        })
                }
                else {
                    this.setState({ loadData: false })
                }
            })

        axios.get(`${serverUrl}/consumer/get/get-user/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    loginUser(data.user)
                }
                else {
                    return this.props.history.replace('/login')
                }
            })
            .catch((e) => console.log(e))
        axios.get(`${serverUrl}/admin/get/get-promotion-by-group/${user.userGroup}`)
            .then((res) => {
                const { data, success } = res.data
                if (success) {
                    promotionAds(data)
                    if (!data.length) {
                        this.setState({ isAds: false })
                    }
                }
            })
            .catch((e) => console.log(e))
    }

    updateGrocery() {
        const { currentTopic, productData } = this.state
        var groc = []
        var groceryData = []

        for (var j in productData) {
            if (currentTopic.topicId === productData[j].topic._id) {
                groc.push(productData[j])
            }
        }

        for (var i in groc) {
            var obj = {
                title: groc[i].groceryName,
                value: i,
                key: i,
                imageLink: groc[i].imageLink,
                buId: groc[i].buId,
                children: []
            }
            var { excelFileObj } = groc[i]
            var count = 0
            for (var j in excelFileObj) {
                if (j !== "null") {
                    obj.children.push({
                        title: j,
                        value: `${i}-${count}`,
                        key: `${i}-${count}`,
                        children: []
                    })
                    var childCount = 0
                    for (var k in excelFileObj[j]) {
                        if (k !== "null") {
                            obj.children[count].children.push({
                                title: k,
                                value: `${i}-${count}-${childCount}`,
                                key: `${i}-${count}-${childCount}`,
                                children: []
                            })
                            for (var l in excelFileObj[j][k]) {
                                obj.children[count].children[childCount].children.push({
                                    title: excelFileObj[j][k][l]['product_title'],
                                    value: `${i}-${count}-${childCount}-${l}`,
                                    key: `${i}-${count}-${childCount}-${l}`,
                                    data: excelFileObj[j][k][l]
                                })
                            }
                            childCount++
                        }
                    }
                    count++
                }
            }
            groceryData.push(obj)
            this.setState({ groceryData })
        }
    }

    updateTab = e => {
        this.setState({ loadingData: true }, () => {
            const { shoppinData, allTopics } = this.state

            setTimeout(() => {
                let { orderOfData } = shoppinData[e]
                this.setState({ orderOfData, currentTopic: allTopics[e], currentData: shoppinData[e], loadingData: false, selectedData: [] })
            }, 300)
        })
    }

    updateBuy = async () => {
        const { user } = this.props
        let { selectedData, modalData } = this.state
        var values = {}
        if (!selectedData.length) {
            return message.warning('Please Select Items First')
        }

        this.setState({ buyLoading: true })

        values.orderOfData = await this.updateChild(selectedData)
        values.email = user.email
        values.name = user.userName
        values.telNo = user.telNo ? user.telNo : null
        values.userId = user._id
        values.country = user.country

        let savings = 0
        let totalPrice = []
        var ind = 0
        for (var i of values.orderOfData) {
            totalPrice.push(0)
            for (var j of i.children) {
                for (var k of j.children) {
                    for (var l of k.children) {
                        var save = Math.floor(parseInt(l.data['inputNumber']) * l.data['condition']) * Math.abs(l.data['savings'])
                        totalPrice[ind] += l.data['current_price'] * l.data['inputNumber']
                        savings += save
                    }
                }
            }
            ind++
        }

        ind = 0
        var obj = {}
        const { orderOfData } = values
        for (var i in orderOfData) {
            await axios.post(`${serverUrl}/get/get-range`, { postalCode: user.postalCode, userId: orderOfData[i].buId, minAmount: totalPrice[i] })
                .then(async (res) => {
                    const { data } = res
                    if (data.success) {
                        if (!data.inRegion) {
                            await axios.post(`${serverUrl}/admin/get/get-range`, { postalCode: user.postalCode, country: user.country })
                                .then(async (res) => {
                                    const { data } = res
                                    if (data.inRegion) {
                                        await axios.post(`${serverUrl}/admin/get/get-cost/${user.country}`, { minAmount: totalPrice[i] })
                                            .then((res) => {
                                                const { data } = res
                                                if (data.success && data.data) {
                                                    obj = { ...data.data, totalPrice: totalPrice[ind], title: orderOfData[i].title }
                                                    if (data.data.cuDelivery <= totalPrice[ind]) {
                                                        obj.deliveryCost = 0
                                                    }
                                                    else {
                                                        obj.deliveryCost = data.data.cuCost
                                                    }
                                                    modalData.push(obj)
                                                    ind++
                                                }
                                                else {
                                                    ind++
                                                }
                                            })
                                            .catch((e) => {
                                                ind++
                                            })
                                    }
                                    else {
                                        ind++
                                    }
                                })
                                .catch((e) => {
                                    ind++
                                })
                        }
                        else {
                            obj = { ...data.data, totalPrice: totalPrice[ind], title: orderOfData[i].title }
                            if (data.data.cuDelivery <= totalPrice[ind]) {
                                obj.deliveryCost = 0
                            }
                            else {
                                obj.deliveryCost = data.data.cuCost
                            }
                            modalData.push(obj)
                            ind++
                        }
                    }
                    else {
                        ind++
                    }
                })
                .catch((e) => {
                    ind++
                })
        }
        this.setState({ savings, values })
        if (modalData.length) {
            this.setState({ showModal: true })
        }
        else {
            this.confirmBuy()
        }
    }

    confirmBuy = () => {
        const { user } = this.props
        const { savings, values } = this.state
        this.setState({ deliverBtn: true })
        axios.post(`${serverUrl}/consumer/user/update-savings`, {
            _id: user._id,
            savings
        })
        axios.post(`${serverUrl}/consumer/user/update-buy`, values)
            .then((result) => {
                const { data } = result
                if (data.success) {
                    this.deleteItems1()
                }
                else {
                    this.setState({ buyLoading: false, deliverBtn: false, modalData: [], showModal: false, savings: 0, values: {} })
                    this.openNotification('Error', data.message, 'close-circle')
                }
            })
            .catch(() => {
                this.setState({ buyLoading: false, deliverBtn: false, modalData: [], showModal: false, savings: 0, values: {} })
                this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle')
            })
    }

    updateSavings = async () => {
        const { user, loginUser } = this.props
        let { selectedData } = this.state
        if (!selectedData.length) {
            return message.warning('Please Select Items First')
        }

        this.setState({ saveLoading: true })
        let savings = 0

        var orderOfData = await this.updateChild(selectedData)
        for (var i of orderOfData) {
            for (var j of i.children) {
                for (var k of j.children) {
                    for (var l of k.children) {
                        var save = Math.floor(parseInt(l.data['inputNumber']) * l.data['condition']) * Math.abs(l.data['savings'])
                        savings += save
                    }
                }
            }
        }

        axios.post(`${serverUrl}/consumer/user/update-savings`, {
            _id: user._id,
            savings
        })
            .then((result) => {
                const { data } = result
                if (data.success) {
                    this.openNotification('Success', data.message, 'check')
                    loginUser(data.user)
                    this.setState({ saveLoading: false })
                    setTimeout(() => {
                        window.location.reload()
                    }, 300)
                }
                else {
                    this.setState({ saveLoading: false })

                    this.openNotification('Success', data.message, 'check')
                }
            })
            .catch(() => {
                this.setState({ saveLoading: false })

                this.openNotification('Success', 'Something Went Wrong!!!', 'check')
            })
    }

    deleteItems = async () => {
        let { selectedData, currentData } = this.state
        if (!selectedData.length) {
            return message.warning('Please Select Items First')
        }
        this.setState({ loading: true })
        currentData.orderOfProducts = currentData.orderOfProducts.filter(v => selectedData.indexOf(v) === -1)
        currentData.orderOfData = await this.updateChild(currentData.orderOfProducts)
        this.handleUpdate(currentData)
    }

    handleUpdate = values => {
        axios.post(`${serverUrl}/consumer/post/add-shoppinglist`, values)
            .then((result) => {
                if (result.data.success) {
                    this.openNotification('Success', 'Deleted Successfully', 'check')
                    this.setState({ loading: false })
                    setTimeout(() => {
                        window.location.reload()
                    }, 300)
                }
                else {
                    this.openNotification(title, result.data.message, 'close-circle', 'red')
                    this.setState({ loading: false, textValue: 'Update' })
                }
            })
            .catch((err) => {
                this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                this.setState({ loading: false, textValue: 'Update' })
            })
    }

    deleteItems1 = async () => {
        let { selectedData, currentData } = this.state
        if (!selectedData.length) {
            return message.warning('Please Select Items First')
        }
        currentData.orderOfProducts = currentData.orderOfProducts.filter(v => selectedData.indexOf(v) === -1)
        currentData.orderOfData = await this.updateChild(currentData.orderOfProducts)
        this.handleUpdate1(currentData)
    }

    handleUpdate1 = values => {
        axios.post(`${serverUrl}/consumer/post/add-shoppinglist`, values)
            .then((result) => {
                if (result.data.success) {
                    this.openNotification('Success', 'Buy Successfully', 'check')
                    this.setState({ buyLoading: false })
                    setTimeout(() => {
                        window.location.reload()
                    }, 300)
                }
                else {
                    this.openNotification(title, result.data.message, 'close-circle', 'red')
                    this.setState({ loading: false, textValue: 'Update' })
                }
            })
            .catch((err) => {
                this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                this.setState({ loading: false, textValue: 'Update' })
            })
    }

    updateSelect = (e, price) => {
        let { selectedData, quantity, totalPrice } = this.state
        if (selectedData.indexOf(e) === -1) {
            selectedData.push(e)
            if (!quantity[e]) {
                this.setState({ quantity: { ...quantity, [e]: 1 }, totalPrice: { ...totalPrice, [e]: price } })
            }
        }
        else {
            selectedData = selectedData.filter(v => v !== e)
            this.setState({ quantity: { ...quantity, [e]: 0 }, totalPrice: { ...totalPrice, [e]: 0 } })
        }
        this.setState({ selectedData })
    }

    updateChild = async e => {
        await this.updateGrocery()
        const { groceryData, quantity } = this.state
        let myData = []
        for (var v of e) {
            for (var i of groceryData) {
                if (i.value === v.charAt(0)) {
                    var data = myData.filter(d => d.value === v.charAt(0))
                    if (!data.length) {
                        myData.push({
                            title: i.title,
                            value: i.value,
                            key: i.key,
                            imageLink: i.imageLink,
                            buId: i.buId,
                            children: []
                        })
                    }
                    const { children } = i
                    for (var j of children) {
                        if (j.value === v.slice(0, 3)) {
                            var data1 = myData.map((d, index) => {
                                if (d.value === v.charAt(0)) {
                                    return index
                                }
                            })
                            var key = data1.filter(d => d !== undefined)[0]
                            var productGroup = myData[key].children.filter(d => d.value === j.value)
                            if (!productGroup.length) {
                                myData[key].children.push({
                                    title: j.title,
                                    value: j.value,
                                    key: j.key,
                                    children: []
                                })
                            }
                            var groupChild = j.children
                            for (var k of groupChild) {
                                if (k.value === v.slice(0, 5)) {
                                    var data2 = myData[key].children.map((d, ind) => {
                                        if (d.value === v.slice(0, 3)) {
                                            return ind
                                        }
                                    })
                                    var key1 = data2.filter(d => d !== undefined)[0]
                                    var productType = myData[key].children[key1].children.filter(d => d.value === k.value)
                                    if (!productType.length) {
                                        myData[key].children[key1].children.push({
                                            title: k.title,
                                            value: k.value,
                                            key: k.key,
                                            children: []
                                        })
                                    }
                                    var titleChild = k.children
                                    for (var l of titleChild) {
                                        if (l.value === v) {
                                            var data3 = myData[key].children[key1].children.map((d, ind) => {
                                                if (d.value === v.slice(0, 5)) {
                                                    return ind
                                                }
                                            })

                                            var key2 = data3.filter(d => d !== undefined)[0]
                                            var productTitle = myData[key].children[key1].children[key2].children.filter(d => d.value === k.value)
                                            if (!productTitle.length) {
                                                l.data['inputNumber'] = quantity[l.value]
                                                myData[key].children[key1].children[key2].children.push({
                                                    title: l.title,
                                                    value: l.value,
                                                    key: l.key,
                                                    data: l.data
                                                })
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }

        return myData
    }

    updateInput = (e, price) => {
        let { quantity, totalPrice, selectedData } = this.state
        const { name, value } = e.target
        if (value > 0) {
            if (value <= 99) {
                this.setState({ quantity: { ...quantity, [name]: value }, totalPrice: { ...totalPrice, [name]: value * price } })
            }
            else {
                this.setState({ quantity: { ...quantity, [name]: 99 }, totalPrice: { ...totalPrice, [name]: 99 * price } })
            }
        }
        else {
            this.setState({ quantity: { ...quantity, [name]: 0 }, totalPrice: { ...totalPrice, [name]: 0 } })
        }
        if (selectedData.indexOf(name) === -1) {
            selectedData.push(name)
        }
        else {
            selectedData = [...new Set(selectedData)]
        }
        this.setState({ selectedData })
    }

    addProduct = () => {
        let { currentTopic, productData, currentProduct } = this.state
        currentProduct = productData.filter((v, i) => currentTopic.topicId === v.topic._id)
        this.setState({ showAdd: true, currentProduct })
    }

    handleCancel = () => {
        this.setState({ showModal: false, modalData: [], buyLoading: false })
    }

    render() {
        let { loadData, isData, allTopics, loading, orderOfData, showAdd, currentTopic, quantity, loadingData, isAds, buyLoading, currentProduct, saveLoading, showModal, modalData, deliverBtn } = this.state;

        const { ads, user } = this.props

        if (ads?.length) {
            var item = ads[Math.floor(Math.random() * ads.length)];
        }

        // return (
        //     <a href={url} target="_blank">Download Pdf</a>
        // )


        return (
            <div>
                <div className="header bg-light">
                    <header className="header bg-light">
                        <nav className="navbar  navbar-light">
                            <div className="container">
                                <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                                <h1 className="show-window">Shoppinglist Products</h1>
                                <h4 className="show-window">Savings: € {roundToTwo(user.savings)}</h4>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <h1 className="show-mobile">Shoppinglist Products</h1>
                                <h4 className="show-mobile">Savings: € {roundToTwo(user.savings)}</h4>

                                <div className="collapse navbar-collapse" id="collapsingNavbar">
                                    <ul className="navbar-nav ml-auto">

                                        <li className="nav-item"><Link className="nav-link" to="/">Home</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Settings
                                                </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <Link className="dropdown-item" to="/pantry-setting">Pantry settings </Link>
                                                <Link className="dropdown-item" to="/brochure-setting">Brochure settings</Link>
                                                <Link className="dropdown-item" to="/shopping-setting">ShoppingList settings</Link>
                                                <Link className="dropdown-item" to="/1to1-setting">1 To 1 Offer settings</Link>
                                                <Link className="dropdown-item" to="/notification-setting">Notifcations settings</Link>
                                            </div>
                                        </li>
                                        <li className="nav-item"><Link className="nav-link" to="/pantry">Pantry</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/brochure">Brochure</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/1to1">1 To 1 Offer</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </header>
                </div>
                <Modal
                    visible={showModal}
                    title="Buy Products"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={deliverBtn} onClick={this.confirmBuy}>
                            Buy
                        </Button>,
                    ]}
                >
                    <div style={{ float: 'right' }}>
                        Deliver Product: <Switch />
                    </div>
                    <div style={{ marginTop: 30 }}>
                        {modalData.map((v, i) => {
                            return (
                                <div key={i} style={{ marginTop: 20 }}>
                                    <Descriptions title={v.title} bordered column={1}>
                                        <Descriptions.Item label="Total Price">€ {roundToTwo(v.totalPrice)}</Descriptions.Item>
                                        <Descriptions.Item label="Delivery Cost">€ {v.deliveryCost}</Descriptions.Item>
                                    </Descriptions>
                                </div>
                            )
                        })}
                    </div>
                </Modal>
                <div className="main-content-bg2">
                    <section style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="card3" style={{ marginTop: 250, marginBottom: 50 }}>
                            {isData && !loadData ? <div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Tabs onTabClick={this.updateTab} defaultActiveKey="0" tabPosition={"top"} className="tabs">
                                        {allTopics.map((a, i) => (
                                            <TabPane tab={a.topicName} key={i}>
                                                <div style={{ display: 'flex', justifyContent: 'center', margin: 20 }}>
                                                    <Button style={{ marginRight: 5, marginBottom: 10 }} onClick={this.addProduct}>Add</Button>
                                                    <Button type="primary" style={{ marginLeft: 5, marginRight: 5, marginBottom: 10 }} loading={saveLoading} onClick={this.updateSavings}>Savings</Button>
                                                    <Button type="dashed" loading={buyLoading} onClick={this.updateBuy} style={{ marginLeft: 5, marginRight: 5, marginBottom: 10 }}>Buy</Button>
                                                    <Popconfirm
                                                        title="Are you sure delete these selected list?"
                                                        onConfirm={this.deleteItems}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button type="danger" style={{ marginLeft: 5, marginRight: 5, marginBottom: 10 }} loading={loading}>Delete</Button>
                                                    </Popconfirm>
                                                </div>
                                                {!loadingData ? <div style={{ minHeight: 500, maxHeight: 800, overflowY: 'scroll' }}>
                                                    {Object.entries(orderOfData).map((v, i) => {
                                                        return v[1].children.map((y, j) => {
                                                            return y.children.map((z, k) => {
                                                                return <div key={`${i}-${j}-${k}`}>
                                                                    <div className="list-head">
                                                                        <p className="set-size" style={{ flex: 1, borderRight: '1px solid black' }}>{z.title}</p>
                                                                        <p className="set-size" style={{ flex: 1 }}>{v[1].title}</p>
                                                                    </div>
                                                                    <div>
                                                                        <List
                                                                            itemLayout="horizontal"
                                                                            dataSource={z.children}
                                                                            renderItem={(item, key) => (
                                                                                <List.Item
                                                                                    className="list-item"
                                                                                >
                                                                                    <div style={{ width: 0, backgroundColor: 'green', transition: '0.3s', height: '100px' }} id={`${i}-${j}-${k}-${key}-right`} className="list-copy">
                                                                                    </div>
                                                                                    <div style={{ display: 'flex', width: '100%', transition: '0.3s', height: '100px' }} id={`${i}-${j}-${k}-${key}`} className="list-data">
                                                                                        <List.Item.Meta
                                                                                            style={{ padding: 20 }}
                                                                                            description={
                                                                                                <div style={{
                                                                                                    display: 'flex',
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <Input className="input-box" style={{ marginRight: 5, marginTop: 5 }} type="number" name={item.value} value={quantity[item.value]} maxLength={2} min={0} defaultValue={0} onChange={e => this.updateInput(e, item.data['current_price'])} />
                                                                                                    <Checkbox style={{ marginRight: 5, marginTop: 10 }} checked={Boolean(quantity[item.value])} onClick={() => this.updateSelect(item.value, item.data['current_price'])}>
                                                                                                    </Checkbox>
                                                                                                    <div className="img-div">
                                                                                                        <img src={item.data['image_link']} style={{ marginTop: -50, height: 140 }} />
                                                                                                    </div>
                                                                                                    <div className="mobile-ver">
                                                                                                        <div className="mid-div" style={{ flex: 2 }}>
                                                                                                            <p className="set-size" style={{ flex: 1 }}>{item.data['product_title']}</p>
                                                                                                        </div>
                                                                                                        {item.suggest && <Badge count={"Suggest"}>
                                                                                                        </Badge>}
                                                                                                        <div className="last-div" style={{ flexDirection: 'column' }}>
                                                                                                            <p className="set-size" style={{ flex: 1 }}>€ {item.data['current_price']}</p>
                                                                                                            <p className="set-size">{item.data['unit_sold']}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </List.Item>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            })
                                                        })
                                                    })}
                                                </div> : <Skeleton paragraph={{ rows: 10 }} active />}
                                            </TabPane>
                                        ))}
                                    </Tabs>
                                </div>
                            </div> : !isData && loadData && isAds && ads?.length ? <div style={{ width: '100%' }}>
                                <h2 className="ads-show">{item.name}</h2>
                                <h4 className="ads-show">{item.description}</h4>
                                <img src={item.image.url} className="image-ads" />
                                <h4 className="ads-show1">{moment(item.startDate).format('LL')} to {moment(item.endDate).format('LL')}</h4>
                            </div> : !isData && loadData ? <Skeleton paragraph={{ rows: 10 }} active /> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 200, paddingBottom: 200 }}>
                                <h1>OOPS No ShoppingList Products Found!!!</h1>
                            </div>}
                        </div>
                    </section>

                    <Footer {...this.props} />

                    {showAdd ? <AddShopping visible={showAdd} productData={currentProduct} currentTopic={currentTopic} disableModal={() => this.setState({ showAdd: false })} /> : null}
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        ads: state.authReducer.ads,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
        promotionAds: (ads) => dispatch(promotionAds(ads)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(ShoppingList)