import React from 'react';
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { connect } from 'react-redux';
import { Modal, Button, Form, Select, Avatar, notification, Icon } from 'antd';
import axios from 'axios'
import { serverUrl } from '../../Config/api'

const title = "Error"

const { Option } = Select

class AddComp extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disable: false,
            show: true,
            productData: this.props.productData,
            selectedIndex: 0,
            groceryName: [],
            productGroup: [],
            productType: [],
            productName: [],
            groceryData: [],
            textValue: 'Update'
        }
    }

    componentDidMount() {
        const { productData, groceryName } = this.state
        const { currentTopic } = this.props

        for (var i of productData) {
            if (currentTopic.topicId === i.topic._id) {
                groceryName.push(i.groceryName)
            }
        }

        this.setState({ groceryName })
    }


    handleOk = () => {
        this.setState({ loading: true });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    updateGrocery = e => {
        const { productData } = this.state
        const { resetFields } = this.props.form
        resetFields(['productGroup', 'productType', 'productNames'])
        this.setState({
            productGroup: Object.keys(productData[e]['excelFileObj']).filter(v => v !== "null"),
            selectedIndex: e,
            productType: []
        })
    }

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    updateProductGroup = e => {
        const { productData, selectedIndex } = this.state
        const { resetFields } = this.props.form
        resetFields(['productType', 'productNames'])
        this.setState({
            productType: Object.keys(productData[selectedIndex]['excelFileObj'][e]).filter(v => v !== "null")
        })
    }

    updateProductType = e => {
        const { productData, selectedIndex } = this.state
        const { getFieldValue } = this.props.form
        var group = getFieldValue('productGroup')
        this.setState({ productName: productData[selectedIndex]['excelFileObj'][group][e] })
    }

    handleSubmit = e => {
        const { productData, selectedIndex } = this.state
        const { user, form } = this.props
        const { validateFields } = form
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true, textValue: 'Updating' })
                values.userId = user._id
                values.grocery = productData[selectedIndex]['groceryName']
                values.topic = productData[selectedIndex]['topic']
                values.country = user.country
                values.topicId = productData[selectedIndex]['topic']['_id']
                values.topicName = productData[selectedIndex]['topic']['name']
                values.orderOfProducts = []
                axios.post(`${serverUrl}/consumer/post/pantry-by-topic`, {
                    id: user._id,
                    topicId: values.topic._id
                })
                    .then((result) => {
                        const { data } = result
                        var groc = []
                        var groceryData = []

                        for (var j in productData) {
                            if (values.topic._id === productData[j].topic._id) {
                                groc.push(productData[j])
                            }
                        }

                        for (var i in groc) {
                            var obj = {
                                title: groc[i].groceryName,
                                value: i,
                                key: i,
                                children: []
                            }
                            var { excelFileObj } = groc[i]
                            var count = 0
                            for (var j in excelFileObj) {
                                if (j !== "null") {
                                    obj.children.push({
                                        title: j,
                                        value: `${i}-${count}`,
                                        key: `${i}-${count}`,
                                        children: []
                                    })
                                    var childCount = 0
                                    for (var k in excelFileObj[j]) {
                                        if (k !== "null") {
                                            obj.children[count].children.push({
                                                title: k,
                                                value: `${i}-${count}-${childCount}`,
                                                key: `${i}-${count}-${childCount}`,
                                                children: []
                                            })
                                            for (var l in excelFileObj[j][k]) {
                                                obj.children[count].children[childCount].children.push({
                                                    title: excelFileObj[j][k][l]['product_title'],
                                                    value: `${i}-${count}-${childCount}-${l}`,
                                                    key: `${i}-${count}-${childCount}-${l}`,
                                                    data: excelFileObj[j][k][l]
                                                })
                                            }
                                            childCount++
                                        }
                                    }
                                    count++
                                }
                            }
                            groceryData.push(obj)
                        }

                        if (data.success) {
                            values.orderOfProducts = data['result']['orderOfProducts']
                            this.setState({ groceryData, groceryArr: groc }, () => {
                                const { groceryData } = this.state
                                for (i of groceryData) {
                                    if (values.grocery === i.title) {
                                        for (j of i.children) {
                                            if (values.productGroup === j.title) {
                                                for (k of j.children) {
                                                    if (values.productType === k.title) {
                                                        for (l of values.productNames) {
                                                            for (var m of k.children) {
                                                                if (l === m.title) {
                                                                    if (values.orderOfProducts.indexOf(m.value) === -1) {
                                                                        values.orderOfProducts.push(m.value)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            })
                            values.orderOfData = this.updateChild(values.orderOfProducts)
                            values.sharePantry = data['result']['sharePantry']
                            if (data['result']['sharePantry']) {
                                values.authorize = data['result']['authorize']
                            }
                            this.handleUpdate(values)
                        }
                        else {
                            this.setState({ groceryData, groceryArr: groc }, () => {
                                const { groceryData } = this.state
                                for (i of groceryData) {
                                    if (values.grocery === i.title) {
                                        for (j of i.children) {
                                            if (values.productGroup === j.title) {
                                                for (k of j.children) {
                                                    if (values.productType === k.title) {
                                                        for (l of values.productNames) {
                                                            for (var m of k.children) {
                                                                if (l === m.title) {
                                                                    values.orderOfProducts.push(m.value)
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            })
                            values.orderOfData = this.updateChild(values.orderOfProducts)
                            values.sharePantry = false
                            this.handleUpdate(values)
                        }

                    })
            }
        })
    };

    handleUpdate = values => {
        axios.post(`${serverUrl}/consumer/post/add-pantry`, values)
            .then((result) => {
                if (result.data.success) {
                    this.openNotification('Success', result.data.message, 'check')
                    this.setState({ loading: false })
                    setTimeout(() => {
                        window.location.reload()
                    }, 300)
                }
                else {
                    this.openNotification(title, result.data.message, 'close-circle', 'red')
                    this.setState({ loading: false, textValue: 'Update' })
                }
            })
            .catch((err) => {
                this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                this.setState({ loading: false, textValue: 'Update' })
            })
    }

    updateChild = e => {
        const { groceryData } = this.state
        let myData = []
        for (var v of e) {
            for (var i of groceryData) {
                if (i.value === v.charAt(0)) {
                    var data = myData.filter(d => d.value === v.charAt(0))
                    if (!data.length) {
                        myData.push({
                            title: i.title,
                            value: i.value,
                            key: i.key,
                            children: []
                        })
                    }
                    const { children } = i
                    for (var j of children) {
                        if (j.value === v.slice(0, 3)) {
                            var data1 = myData.map((d, index) => {
                                if (d.value === v.charAt(0)) {
                                    return index
                                }
                            })
                            var key = data1.filter(d => d !== undefined)[0]
                            var productGroup = myData[key].children.filter(d => d.value === j.value)
                            if (!productGroup.length) {
                                myData[key].children.push({
                                    title: j.title,
                                    value: j.value,
                                    key: j.key,
                                    children: []
                                })
                            }
                            var groupChild = j.children
                            for (var k of groupChild) {
                                if (k.value === v.slice(0, 5)) {
                                    var data2 = myData[key].children.map((d, ind) => {
                                        if (d.value === v.slice(0, 3)) {
                                            return ind
                                        }
                                    })
                                    var key1 = data2.filter(d => d !== undefined)[0]
                                    var productType = myData[key].children[key1].children.filter(d => d.value === k.value)
                                    if (!productType.length) {
                                        myData[key].children[key1].children.push({
                                            title: k.title,
                                            value: k.value,
                                            key: k.key,
                                            children: []
                                        })
                                    }
                                    var titleChild = k.children
                                    for (var l of titleChild) {
                                        if (l.value === v) {
                                            var data3 = myData[key].children[key1].children.map((d, ind) => {
                                                if (d.value === v.slice(0, 5)) {
                                                    return ind
                                                }
                                            })

                                            var key2 = data3.filter(d => d !== undefined)[0]
                                            var productTitle = myData[key].children[key1].children[key2].children.filter(d => d.value === k.value)
                                            if (!productTitle.length) {
                                                myData[key].children[key1].children[key2].children.push({
                                                    title: l.title,
                                                    value: l.value,
                                                    key: l.key,
                                                    data: l.data
                                                })
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }

        return myData
    }

    render() {
        const { loading, groceryName, productGroup, productType, productName, textValue } = this.state
        const { visible, disableModal, form } = this.props
        const { getFieldDecorator } = form;
        return (
            <div>
                <div>
                    <Modal
                        visible={visible}
                        title="Pantry Add"
                        onCancel={disableModal}
                        footer={[
                            <Button key="back" onClick={disableModal}>
                                Close
                            </Button>
                        ]}
                    >
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            <Form
                                className="login-form form-w"
                                style={{ width: 300 }}
                                hideRequiredMark={true}
                                onSubmit={this.handleSubmit}
                                encType="multipart/form-data">
                                <h1 className="heading1" >Pantry Add</h1>
                                <Form.Item
                                    label="Grocery"
                                >
                                    {getFieldDecorator('grocery', {
                                        rules: [{ required: true, message: 'Please Select Grocery!' }],
                                    })(
                                        <Select
                                            showSearch
                                            style={{ backgroundColor: '#fff' }}
                                            placeholder="Select Grocery"
                                            optionFilterProp="children"
                                            onChange={this.updateGrocery}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                groceryName.map((v, i) => {
                                                    return <Option value={i} key={i}>{v}</Option>
                                                })
                                            }
                                        </Select>,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Product Group"
                                >
                                    {getFieldDecorator('productGroup', {
                                        rules: [{ required: true, message: 'Please Select Product Group!' }],
                                    })(
                                        <Select
                                            showSearch
                                            style={{ backgroundColor: '#fff' }}
                                            placeholder="Select Product Group"
                                            optionFilterProp="children"
                                            onChange={this.updateProductGroup}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                productGroup.map((v, i) => {
                                                    return <Option value={v} key={i}>{v}</Option>
                                                })
                                            }
                                        </Select>,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Product Type"
                                >
                                    {getFieldDecorator('productType', {
                                        rules: [{ required: true, message: 'Please Select Product Type!' }],
                                    })(
                                        <Select
                                            showSearch
                                            style={{ backgroundColor: '#fff' }}
                                            placeholder="Select Product Type"
                                            optionFilterProp="children"
                                            onChange={this.updateProductType}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                productType.map((v, i) => {
                                                    return <Option value={v} key={i}>{v}</Option>
                                                })
                                            }
                                        </Select>,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Product Names"
                                >
                                    {getFieldDecorator('productNames', {
                                        rules: [{ required: true, message: 'Please Select Product Names!' }],
                                    })(
                                        <Select
                                            showSearch
                                            mode="multiple"
                                            style={{ backgroundColor: '#fff' }}
                                            placeholder="Select Product Names"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                productName.map((v, i) => {
                                                    return <Option value={v['product_title']} key={i} style={{ display: 'flex' }}>
                                                        <Avatar src={v['image_link']} style={{ marginRight: 10 }} />
                                                        {v['product_title']}
                                                    </Option>
                                                })
                                            }
                                        </Select>,
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>{textValue}</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}



const mapStateToProps = (state) => {

    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
        removeUser: () => dispatch(removeUser())
    }
}

const AddCompForm = Form.create({ name: 'normal_login' })(AddComp);

export default connect(mapStateToProps, mapDispatchToProps)(AddCompForm)