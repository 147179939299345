import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../../Redux/actions/authActions'
import logo from '../../assets/pantrylogo.png'
import { Icon, Input, Button, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import Footer from '../../Components/Footer'
import axios from 'axios'
import moment from 'moment'
import PDFDocument from '@react-pdf/pdfkit'
import blobStream from 'blob-stream'
import { serverUrl } from '../../Config/api'

function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2")
}


class Invoice extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRowKeys: [],
            loading: false,
            filteredInfo: null,
            sortedInfo: null,
            searchText: '',
            searchedColumn: '',
            tableData: []
        }
    }

    componentDidMount() {
        const { user } = this.props
        const { tableData } = this.state
        if (!user) {
            return this.props.history.replace('/login')
        }

        axios.get(`${serverUrl}/consumer/get/cu-invoice/${user._id}`)
            .then((res) => {
                const { data, success } = res.data
                if (success) {
                    for (var i in data) {
                        tableData.push({
                            key: i,
                            date: moment(data[i].create).format("LL"),
                            name: data[i].groceryName,
                            in_id: data[i]._id,
                            action: data[i]
                        });
                    }
                    this.setState({ tableData })
                }
            })
            .catch((e) => console.log(e))
    }

    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null,
        });
    };

    setAgeSort = () => {
        this.setState({
            sortedInfo: {
                order: 'ascend',
                columnKey: 'in_id',
            },
        });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    downloadPdf(v) {
        const { allData, buData } = v
        const doc = new PDFDocument();
        const stream = doc.pipe(blobStream());

        doc.fontSize(16).text('Invoice', 10, 50)
        doc
            .fontSize(16)
            .font('Times-Roman')
            .text(moment(v.create).format('LL'), 10, 100);

        doc
            .font('Times-Bold')
            .text('Supplier:', 10, 120);

        doc
            .font('Times-Roman')
            .text(buData.userName, 10, 150);

        if (buData.streetAddress || buData.houseNo || buData.houseNoExtra) {
            doc
                .text(`${buData.streetAddress} ${buData.houseNo} ${buData.houseNoExtra}`, 10, 170);
        }

        if (buData.postalCode || buData.city) {
            doc
                .text(`${buData.streetAddress} ${buData.houseNo} ${buData.houseNoExtra}`, 10, 170);
        }

        doc
            .text(v.country, 10, 210);

        doc
            .font('Times-Bold')
            .text('Client:', 10, 230);

        doc
            .font('Times-Roman')
            .text(v.cuName, 10, 250);

        doc
            .font('Times-Bold')
            .text('Products:', 10, 280);

        doc
            .font('Times-Roman')
            .text('Input Number', 10, 310);

        doc.text('Product Name', 120, 310);

        doc.text('Promo Text', 250, 310);

        doc.text('Unit Sold', 350, 310);
        doc.text('Price', 430, 310);
        doc.text('Total Price', 480, 310);

        doc.underline(10, 326, 600, 1.5)
        doc.underline(10, 305, 600, 1.5)

        var position = 335
        var totalPrice = 0
        var productPrice = 0
        for (var obj of allData) {
            productPrice = Math.ceil(obj.inputNumber * obj.condition) * obj['current_price']
            doc.text(`${obj.inputNumber}`, 10, position);
            doc.text(obj.product_title.slice(0, 15), 120, position);
            doc.text(obj['orange_block_info/promoText'].slice(0, 10), 250, position);
            doc.text(obj['unit_sold'], 350, position);
            doc.text(`€ ${obj['current_price']}`, 430, position);
            doc.text(`€ ${productPrice}`, 480, position);

            position = position + 20
            totalPrice = totalPrice + productPrice
        }

        doc.text('Total:', 430, position + 30)
        doc.text(`€ ${totalPrice}`, 480, position + 30)
        doc.text('BTW 9%', 410, position + 60)
        doc.text(`€ ${roundToTwo(9 / 109 * totalPrice)}`, 480, position + 60)
        // doc.underline(480, position + 90, 70, 1.5)
        // doc.text(`Total incl BTW: `, 365, position + 110)
        // doc.text(`€ ${roundToTwo((9 / 109 * totalPrice) + totalPrice)}`, 480, position + 110)

        doc.end();
        stream.on('finish', function () {
            var url = stream.toBlobURL('application/pdf');
            window.open(url, '_blank')
        });
    }

    render() {
        let { sortedInfo, tableData } = this.state;
        sortedInfo = sortedInfo || {};

        const columns = [
            {
                title: 'Invoice Id',
                dataIndex: 'in_id',
                ellipsis: true,
                sorter: (a, b) => a.in_id.length - b.in_id.length,
                sortOrder: sortedInfo.columnKey === 'in_id' && sortedInfo.order
            },
            {
                title: 'Name',
                dataIndex: 'name',
                ellipsis: true,
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order
            },
            {
                title: 'Date',
                dataIndex: 'date',
                ellipsis: true,
                sorter: (a, b) => a.date.length - b.date.length,
                sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order
            },
            {
                title: 'Operation',
                dataIndex: 'action',
                ellipsis: true,
                render: (v) => <div style={{
                    width: '100%',
                    paddingLeft: 50,
                    paddingRight: 50,
                    display: 'flex',
                    justifyContent: 'space-around'
                }}>
                    <Icon type="download" onClick={() => this.downloadPdf(v)} style={{ marginRight: 10 }} />
                    <Icon type="delete" />
                </div>
            }
        ];
        return (
            <div>
                <div className="advertisement2">
                </div>

                <div className="header bg-light">
                    <header className="header bg-light">
                        <nav className="navbar  navbar-light">
                            <div className="container">
                                <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="collapsingNavbar">
                                    <ul className="navbar-nav ml-auto">

                                        <li className="nav-item"><Link className="nav-link" to="/">Home</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Settings
                                                </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <Link className="dropdown-item" to="/pantry-setting">Pantry settings </Link>
                                                <Link className="dropdown-item" to="/brochure-setting">Brochure settings</Link>
                                                <Link className="dropdown-item" to="/shopping-setting">ShoppingList settings</Link>
                                                <Link className="dropdown-item" to="/1to1-setting">1 To 1 Offer settings</Link>
                                                <Link className="dropdown-item" to="/notification-setting">Notifications settings</Link>
                                            </div>
                                        </li>
                                        <li className="nav-item"><Link className="nav-link" to="/pantry">Pantry</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/brochure">Brochure</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/shoppinglist">ShoppingList</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/1to1">1 To 1 Offer</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </header>
                </div>

                <div className="main-content-bg">
                    <section>
                        <section className="main-content-bg2">
                            <div className="center1">
                                <div className="card2">
                                    <h2><b>OVERVIEW</b></h2>
                                    <br />
                                    <h4><b>Invoices</b></h4>
                                    <br />
                                    <br />
                                    <Table
                                        className="components-table-demo-nested"
                                        onChange={this.handleChange}
                                        columns={columns}
                                        dataSource={tableData}
                                        bordered
                                        title={() => <div className="table-operations">
                                            <Button onClick={this.setAgeSort}>Sort Invoice-id</Button>
                                            <Button onClick={this.clearAll}>Clear sorters</Button>
                                        </div>}
                                        style={{ width: '100%' }}
                                        tableLayout="fixed"
                                        scroll={{ y: 300 }}
                                    />
                                </div>
                            </div>
                        </section>

                        <Footer {...this.props} />
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Invoice)