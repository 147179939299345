import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser, promotionAds } from '../../Redux/actions/authActions'
import logo from '../../assets/pantrylogo.png'
import { Form, Icon, Popover, Button, notification, Collapse, Tabs, List, Skeleton, message, Checkbox, Badge, Popconfirm } from 'antd';
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from '../../Components/Footer'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { serverUrl } from '../../Config/api'

const { Panel } = Collapse

var title = "Error"

const { TabPane } = Tabs

function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2")
}


class Brochure extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disable: false,
            visible: false,
            questionsData: [],
            brochureData: [],
            productsData: {},
            isData: false,
            loadData: true,
            allTopics: [],
            allData: [],
            productData: [],
            showAdd: false,
            topicsData: [],
            orderOfData: [],
            loadingData: false,
            isAds: true,
            selectedData: [],
            currentTopic: [],
            altData: [],
            currentData: [],
            showAlternate: false,
            loadShopping: false,
            loadPantry: false,
            loadSuggest: false
        }
    }

    async componentDidMount() {
        let { topicsData } = this.state
        const { user, promotionAds } = this.props
        if (!user) {
            return this.props.history.replace('/login')
        }
        axios.get(`${serverUrl}/consumer/get/get-user/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    loginUser(data.user)
                }
                else {
                    return this.props.history.replace('/login')
                }
            })
            .catch((e) => console.log(e))
        axios.get(`${serverUrl}/admin/get/get-promotion-by-group/${user.userGroup}`)
            .then((res) => {
                const { data, success } = res.data
                if (success) {
                    promotionAds(data)
                    if (!data.length) {
                        this.setState({ isAds: false })
                    }
                }
            })
            .catch((e) => console.log(e))
        await axios.get(`${serverUrl}/consumer/get/get-all-brochure/${user._id}`)
            .then((res) => {
                var { result, success } = res.data
                var allTopics = []
                if (success) {
                    for (var i in result) {
                        var { topicId, topicName } = result[i]
                        allTopics.push({
                            topicId,
                            topicName
                        })
                    }
                    let { orderOfData, allData, altData } = result[0]
                    axios.get(`${serverUrl}/admin/get/product-by-country/${user.country}`)
                        .then((response) => {
                            var obj = {}
                            let { success, data } = response.data
                            if (success) {
                                for (var i of data) {
                                    obj[i.groceryName] = i['excelFileObj']
                                }

                                for (var i in data) {
                                    topicsData.push(data[i].topic)
                                }
                                var resArr = [];
                                topicsData.forEach((item) => {
                                    var i = resArr.findIndex(x => x.name === item.name);
                                    if (i <= -1) {
                                        resArr.push(item);
                                    }
                                });

                                this.setState({ topicsData })
                            }
                            this.setState({ brochureData: result, isData: true, loadData: false, allTopics, productData: data, orderOfData, currentTopic: allTopics[0], allData, altData, currentData: result[0] })
                        })
                }
                else {
                    this.setState({ loadData: false })
                }
            })

    }

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    updateGrocery() {
        const { currentTopic, productData } = this.state
        var groc = []
        var groceryData = []

        for (var j in productData) {
            if (currentTopic.topicId === productData[j].topic._id) {
                groc.push(productData[j])
            }
        }


        for (var i in groc) {
            var obj = {
                title: groc[i].groceryName,
                value: i,
                key: i,
                imageLink: groc[i].imageLink,
                children: []
            }
            var { excelFileObj } = groc[i]
            var count = 0
            for (var j in excelFileObj) {
                if (j !== "null") {
                    obj.children.push({
                        title: j,
                        value: `${i}-${count}`,
                        key: `${i}-${count}`,
                        children: []
                    })
                    var childCount = 0
                    for (var k in excelFileObj[j]) {
                        if (k !== "null") {
                            obj.children[count].children.push({
                                title: k,
                                value: `${i}-${count}-${childCount}`,
                                key: `${i}-${count}-${childCount}`,
                                children: []
                            })
                            for (var l in excelFileObj[j][k]) {
                                obj.children[count].children[childCount].children.push({
                                    title: excelFileObj[j][k][l]['product_title'],
                                    value: `${i}-${count}-${childCount}-${l}`,
                                    key: `${i}-${count}-${childCount}-${l}`,
                                    data: excelFileObj[j][k][l]
                                })
                            }
                            childCount++
                        }
                    }
                    count++
                }
            }
            groceryData.push(obj)
            this.setState({ groceryData })
        }
    }

    handleSubmit = async e => {
        await this.updateGrocery()
        const { user } = this.props
        const { currentTopic, currentData, selectedData } = this.state
        if (!selectedData.length) {
            return message.warning('Please Select Items First')
        }
        var values = {}
        values.userId = user._id
        values.topic = currentData.topic
        values.country = user.country
        values.topicId = currentTopic.topicId
        values.topicName = currentTopic.topicName
        values.orderOfProducts = []
        this.setState({ loadShopping: true })
        axios.post(`${serverUrl}/consumer/post/shopping-by-topic`, {
            id: user._id,
            topicId: values.topic._id
        })
            .then((result) => {
                const { data } = result

                if (data.success) {
                    values.orderOfProducts = [...data['result']['orderOfProducts'], ...selectedData]
                    values.orderOfProducts = [...new Set(values.orderOfProducts)]
                    values.orderOfData = this.updateChild(values.orderOfProducts)
                    values.shareShopping = data['result']['shareShopping']
                    values.showAlternative = data['result']['showAlternative']
                    values.shareShopping = data['result']['shareShopping']
                    if (data['result']['shareShopping']) {
                        values.authorizeFully = data['result']['authorizeFully']
                        values.authorizeSuggest = data['result']['authorizeSuggest']
                    }
                    this.handleUpdateShopping(values)
                }
                else {
                    values.orderOfProducts = selectedData
                    values.orderOfData = this.updateChild(values.orderOfProducts)
                    values.showShopping = false
                    values.showAlternative = false
                    values.shareShopping = false
                    this.handleUpdateShopping(values)
                }

            })
    };

    handleUpdateShopping = values => {
        axios.post(`${serverUrl}/consumer/post/add-shoppinglist`, values)
            .then((result) => {
                if (result.data.success) {
                    this.openNotification('Success', result.data.message, 'check')
                    this.setState({ loadShopping: false })
                    setTimeout(() => {
                        window.location.reload()
                    }, 300)
                }
                else {
                    this.openNotification(title, result.data.message, 'close-circle', 'red')
                    this.setState({ loadShopping: false, textValue: 'Update' })
                }
            })
            .catch((err) => {
                this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                this.setState({ loadShopping: false, textValue: 'Update' })
            })
    }

    handleSubmit1 = async e => {
        await this.updateGrocery()
        const { user } = this.props
        const { currentTopic, currentData, selectedData } = this.state
        if (!selectedData.length) {
            return message.warning('Please Select Items First')
        }
        var values = {}
        values.userId = user._id
        values.topic = currentData.topic
        values.country = user.country
        values.topicId = currentTopic.topicId
        values.topicName = currentTopic.topicName
        values.orderOfProducts = []
        this.setState({ loadPantry: true })
        axios.post(`${serverUrl}/consumer/post/pantry-by-topic`, {
            id: user._id,
            topicId: values.topic._id
        })
            .then((result) => {
                const { data } = result

                if (data.success) {
                    values.orderOfProducts = [...data['result']['orderOfProducts'], ...selectedData]
                    values.orderOfProducts = [...new Set(values.orderOfProducts)]
                    values.orderOfData = this.updateChild(values.orderOfProducts)
                    values.sharePantry = data['result']['sharePantry']
                    if (data['result']['sharePantry']) {
                        values.authorize = data['result']['authorize']
                    }
                    this.handleUpdatePantry(values)
                }
                else {
                    values.orderOfProducts = selectedData
                    values.orderOfData = this.updateChild(values.orderOfProducts)
                    values.sharePantry = false
                    this.handleUpdatePantry(values)
                }

            })
    };

    handleUpdatePantry = values => {
        axios.post(`${serverUrl}/consumer/post/add-pantry`, values)
            .then((result) => {
                if (result.data.success) {
                    this.openNotification('Success', result.data.message, 'check')
                    this.setState({ loadPantry: false })
                    setTimeout(() => {
                        window.location.reload()
                    }, 300)
                }
                else {
                    this.openNotification(title, result.data.message, 'close-circle', 'red')
                    this.setState({ loadPantry: false, textValue: 'Update' })
                }
            })
            .catch((err) => {
                this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                this.setState({ loadPantry: false, textValue: 'Update' })
            })
    }



    checkLimit(name) {
        const { getFieldValue, setFieldsValue } = this.props.form
        if (getFieldValue(name).length > 4) {
            this.openNotification('Error', 'You can only select Maximun 4!!!', 'close-circle', 'red')
            setFieldsValue({
                [name]: getFieldValue(name).slice(0, 4)
            })

            return false
        }

        return true
    }

    updateChild = async e => {
        await this.updateGrocery()
        const { groceryData } = this.state
        let myData = []
        for (var v of e) {
            for (var i of groceryData) {
                if (i.value === v.charAt(0)) {
                    var data = myData.filter(d => d.value === v.charAt(0))
                    if (!data.length) {
                        myData.push({
                            title: i.title,
                            value: i.value,
                            key: i.key,
                            imageLink: i.imageLink,
                            children: []
                        })
                    }
                    const { children } = i
                    for (var j of children) {
                        if (j.value === v.slice(0, 3)) {
                            var data1 = myData.map((d, index) => {
                                if (d.value === v.charAt(0)) {
                                    return index
                                }
                            })
                            var key = data1.filter(d => d !== undefined)[0]
                            var productGroup = myData[key].children.filter(d => d.value === j.value)
                            if (!productGroup.length) {
                                myData[key].children.push({
                                    title: j.title,
                                    value: j.value,
                                    key: j.key,
                                    children: []
                                })
                            }
                            var groupChild = j.children
                            for (var k of groupChild) {
                                if (k.value === v.slice(0, 5)) {
                                    var data2 = myData[key].children.map((d, ind) => {
                                        if (d.value === v.slice(0, 3)) {
                                            return ind
                                        }
                                    })
                                    var key1 = data2.filter(d => d !== undefined)[0]
                                    var productType = myData[key].children[key1].children.filter(d => d.value === k.value)
                                    if (!productType.length) {
                                        myData[key].children[key1].children.push({
                                            title: k.title,
                                            value: k.value,
                                            key: k.key,
                                            children: []
                                        })
                                    }
                                    var titleChild = k.children
                                    for (var l of titleChild) {
                                        if (l.value === v) {
                                            var data3 = myData[key].children[key1].children.map((d, ind) => {
                                                if (d.value === v.slice(0, 5)) {
                                                    return ind
                                                }
                                            })

                                            var key2 = data3.filter(d => d !== undefined)[0]
                                            var productTitle = myData[key].children[key1].children[key2].children.filter(d => d.value === k.value)
                                            if (!productTitle.length) {
                                                myData[key].children[key1].children[key2].children.push({
                                                    title: l.title,
                                                    value: l.value,
                                                    key: l.key,
                                                    data: l.data
                                                })
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }

        return myData
    }

    linkMenuTop(item) {
        return (
            <Collapse accordion style={{ maxWidth: 200 }}>
                <Panel header="Omschrijving " key="1">
                    <p>{item['additional_product_description']}</p>
                </Panel>
                <Panel header="Ingrediënten" key="2">
                    <p>{Number.isNaN(item['product_ingredients'] * 100) ? item['product_ingredients'] : `${item['product_ingredients'] * 100}%`}</p>
                </Panel>
                <Panel header="Voedingswaarde" key="3">
                    <p>{item['Nutritional Values']}</p>
                </Panel>
                {item['allergy_information'] ? <Panel header="Allergie info" key="4">
                    <p>{item['allergy_information']}</p>
                </Panel> : null}
            </Collapse>
        )
    }

    updateTab = e => {
        this.setState({ loadingData: true }, () => {
            const { brochureData, allTopics } = this.state
            setTimeout(() => {
                let { orderOfData, allData, altData } = brochureData[e]
                this.setState({ orderOfData, allData, currentTopic: allTopics[e], currentData: brochureData[e], loadingData: false, altData })
            }, 300)
        })
    }

    updateSelect(e) {
        let { selectedData } = this.state
        if (selectedData.indexOf(e) === -1) {
            selectedData.push(e)
        }
        else {
            selectedData = selectedData.filter(v => v !== e)
        }
        this.setState({ selectedData })
    }

    updateSuggest = () => {
        const { selectedData, currentTopic } = this.state
        const { user } = this.props
        if (!selectedData.length) {
            return message.warning('Please Select Items First')
        }
        const obj = {
            orderOfProducts: selectedData,
            userId: user._id,
            topicId: currentTopic.topicId
        }
        this.setState({ loadSuggest: true })

        axios.post(`${serverUrl}/consumer/post/authorize`, obj)
            .then((result) => {
                if (result.data.success) {
                    this.openNotification('Success', 'Successfully Updated', 'check')
                    this.setState({ loadSuggest: false })
                    setTimeout(() => {
                        window.location.reload()
                    }, 300)
                }
                else {
                    this.openNotification(title, 'Something Went Wrong!', 'close-circle', 'red')
                    this.setState({ loadSuggest: false })
                }
            })
            .catch((err) => {
                this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                this.setState({ loadSuggest: false })
            })
    }

    deleteItems = async () => {
        let { selectedData, currentData } = this.state
        if (!selectedData.length) {
            return message.warning('Please Select Items First')
        }
        this.setState({ loading: true })
        currentData.orderOfProducts = currentData.orderOfProducts.filter(v => selectedData.indexOf(v) === -1)
        currentData.orderOfData = await this.updateChild(currentData.orderOfProducts)
        this.handleUpdate(currentData)
    }

    handleUpdate = values => {
        axios.post(`${serverUrl}/consumer/post/add-brochure`, values)
            .then((result) => {
                if (result.data.success) {
                    this.openNotification('Success', 'Deleted Successfully', 'check')
                    this.setState({ loading: false })
                    setTimeout(() => {
                        window.location.reload()
                    }, 300)
                }
                else {
                    this.openNotification(title, result.data.message, 'close-circle', 'red')
                    this.setState({ loading: false, textValue: 'Update' })
                }
            })
            .catch((err) => {
                this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                this.setState({ loading: false, textValue: 'Update' })
            })
    }

    render() {
        let { loadData, isData, allTopics, orderOfData, loadingData, isAds, loading, allData, showAlternate, altData, loadShopping, loadPantry, loadSuggest } = this.state;

        const { ads, user } = this.props

        if (ads?.length) {
            var item = ads[Math.floor(Math.random() * ads.length)];
        }


        return (
            <div>
                <div className="header bg-light">
                    <header className="header bg-light">
                        <nav className="navbar  navbar-light">
                            <div className="container">
                                <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                                <h1 className="show-window">Brochure</h1>
                                <h4 className="show-window">Savings: € {roundToTwo(user.savings)}</h4>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <h1 className="show-mobile">Brochure</h1>
                                <h4 className="show-mobile">Savings: € {roundToTwo(user.savings)}</h4>

                                <div className="collapse navbar-collapse" id="collapsingNavbar">
                                    <ul className="navbar-nav ml-auto">

                                        <li className="nav-item"><Link className="nav-link" to="/">Home</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Settings
                                                </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <Link className="dropdown-item" to="/pantry-setting">Pantry settings </Link>
                                                <Link className="dropdown-item" to="/brochure-setting">Brochure settings</Link>
                                                <Link className="dropdown-item" to="/shopping-setting">ShoppingList settings</Link>
                                                <Link className="dropdown-item" to="/1to1-setting">1 To 1 Offer settings</Link>
                                                <Link className="dropdown-item" to="/notification-setting">Notifcations settings</Link>
                                            </div>
                                        </li>
                                        <li className="nav-item"><Link className="nav-link" to="/pantry">Pantry</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/shoppinglist">ShoppingList</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/1to1">1 To 1 Offer</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </header>
                </div>
                <div className="main-content-bg2">
                    <section style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                        <div className="card3" style={{ marginTop: 250, marginBottom: 50 }}>
                            {isData && !loadData ? <div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Tabs onTabClick={this.updateTab} defaultActiveKey="0" tabPosition={"top"} className="tabs">
                                        {allTopics.map((v, i) => (
                                            <TabPane tab={v.topicName} key={i}>
                                                <div style={{ display: 'flex', justifyContent: 'center', margin: 20 }}>
                                                    <div className="mobile1">
                                                        <Button style={{ marginRight: 5, marginLeft: 5, marginBottom: 10 }} onClick={() => this.setState({ orderOfData: allData, loadingData: true }, () => {
                                                            setTimeout(() => {
                                                                this.setState({ loadingData: false })
                                                            }, 100)
                                                        })}>ALL</Button>
                                                        <Button
                                                            style={{ marginRight: 5, marginLeft: 5, marginBottom: 10 }}
                                                            onClick={() => this.setState({ orderOfData: altData, loadingData: true, showAlternate: true }, () => {
                                                                setTimeout(() => {
                                                                    this.setState({ loadingData: false })
                                                                }, 100)
                                                            })}
                                                        >Alternative</Button>
                                                    </div>
                                                    <div>
                                                        <Button style={{ marginLeft: 5, marginBottom: 10, marginRight: 5 }} loading={loadPantry} onClick={this.handleSubmit1}>Pantry</Button>
                                                        <Button style={{ marginRight: 5, marginLeft: 5, marginBottom: 10 }} loading={loadShopping} onClick={this.handleSubmit}>Shopping List</Button>
                                                    </div>
                                                    <div>
                                                        <Button style={{ marginRight: 5, marginLeft: 5, marginBottom: 10 }} loading={loadSuggest} onClick={this.updateSuggest}>Suggest</Button>
                                                        <Popconfirm
                                                            title="Are you sure delete these selected list?"
                                                            onConfirm={this.deleteItems}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button type="danger" style={{ marginLeft: 5, marginRight: 5, marginBottom: 10 }} loading={loading}>Delete</Button>
                                                        </Popconfirm>
                                                    </div>
                                                </div>
                                                {!loadingData ? <div style={{ minHeight: 500, maxHeight: 800, overflowY: 'scroll' }}>
                                                    {Object.entries(orderOfData).map((v, i) => {
                                                        return v[1].children.map((y, j) => {
                                                            return y.children.map((z, k) => {
                                                                return <div key={`${i}-${j}-${k}`}>
                                                                    <div className="list-head">
                                                                        <p className="set-size" style={{ flex: 1, borderRight: '1px solid black' }}>{v[1].title}</p>
                                                                        <p className="set-size" style={{ flex: 1 }}>{y.title}/{z.title}</p>
                                                                    </div>
                                                                    <div style={{ marginTop: 50 }}>
                                                                        <List
                                                                            itemLayout="horizontal"
                                                                            dataSource={z.children}
                                                                            renderItem={(item, key) => (
                                                                                <List.Item
                                                                                    style={{ display: 'flex', justifyContent: 'center' }}
                                                                                >
                                                                                    <div className="card all-cards">
                                                                                        <div>
                                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                                                                                <p className="card-head">{v[1].title}</p>
                                                                                                <p className="card-head">{item['data']['product_type']}</p>
                                                                                            </div>
                                                                                            <div className="card-top" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                                                                                <Checkbox style={{ paddingTop: 5, paddingBottom: 5, flex: 1 }} className="card-head" onClick={() => this.updateSelect(item.value)}>
                                                                                                </Checkbox>
                                                                                                <p className="card-head" style={{ flex: 4 }}>{item['data']['product_title']}</p>
                                                                                            </div>
                                                                                            {showAlternate && item.alternate && <div style={{ position: 'absolute', top: 85, left: 20 }}>
                                                                                                <Badge count={"Alternate"}>
                                                                                                </Badge>
                                                                                            </div>}
                                                                                        </div>
                                                                                        <Popover placement="leftTop" content={this.linkMenuTop(item['data'])} trigger="click">
                                                                                            <FontAwesomeIcon icon={faEllipsisV} style={{ position: 'absolute', right: 20, top: 100, fontSize: 25 }} />
                                                                                        </Popover>
                                                                                        {v[1].imageLink ? <a target="_blank" href={item['data']['category_link']}>
                                                                                            <img src={item['data']['image_link']} alt="Avatar" style={{ height: 250, width: '100%', backgroundRepeat: 'no-repeat' }} />
                                                                                        </a> : <img src={item['data']['image_link']} alt="Avatar" style={{ height: 250, width: '100%', backgroundRepeat: 'no-repeat' }} />}
                                                                                        <div className="card-bottom" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                                            <p className="card-foot">€ {item['data']['current_price']}</p>
                                                                                            <p className="card-foot">{item['data']['unit_sold']}</p>
                                                                                        </div>
                                                                                        {item['data']['orange_block_info/promoText'] ? <div>
                                                                                            <div className="card-bottom" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                                <p className="card-head">{item['data']['promotion_period'] ? item['data']['promotion_period'] : 'Niet in aanbieding'}</p>
                                                                                                <p className="card-head mid1">{item['data']['orange_block_info/promoText']}</p>
                                                                                            </div>
                                                                                            <div className="card-bottom" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                                <p className="card-head">{`Normale prijs: € ${item['data']['old_price']} ${item['data']['unit_sold']}`}</p>
                                                                                            </div>
                                                                                        </div> : null}
                                                                                    </div>
                                                                                </List.Item>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            })
                                                        })
                                                    })}
                                                </div> : <Skeleton paragraph={{ rows: 10 }} active />}
                                            </TabPane>
                                        ))}
                                    </Tabs>
                                </div>
                            </div> : !isData && loadData && isAds && ads?.length ? <div style={{ width: '100%' }}>
                                <h2 className="ads-show">{item.name}</h2>
                                <h4 className="ads-show">{item.description}</h4>
                                <img src={item.image.url} className="image-ads" />
                                <h4 className="ads-show1">{moment(item.startDate).format('LL')} to {moment(item.endDate).format('LL')}</h4>
                            </div> : !isData && loadData ? <Skeleton paragraph={{ rows: 10 }} active /> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 200, paddingBottom: 200 }}>
                                <h1>OOPS No Promotion Products Found!!!</h1>
                            </div>}
                        </div>
                    </section>

                    <Footer {...this.props} />
                </div>
            </div>
        )
    }
}

const BrochureForm = Form.create({ name: 'normal_login' })(Brochure);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        ads: state.authReducer.ads,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
        promotionAds: (ads) => dispatch(promotionAds(ads)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(BrochureForm)