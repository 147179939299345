import React from 'react';
import logo from './logo.svg';
import './App.css';
import Routes from './Config/routes'
import { hot } from 'react-hot-loader/root';
import 'antd/dist/antd.css';

import './assets/css/slick.css'
import './assets/css/styles.css'
import './assets/css/contact.css'
import './assets/css/score-setting.css'
import './assets/css/privterms.css'
import './assets/css/faq.css'



class App extends React.Component {
  constructor() {
    super()
  }
  render() {
    return (
      <div>
        <Routes />
      </div>
    )
  }
}

export default hot(App);
