import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Form, Icon, Input, Button, Upload, Select, message, notification } from 'antd';
import { loginUser } from '../../Redux/actions/authActions'
import country from '../../country'
import logo from '../../assets/pantrylogo.png'
import Footer from '../../Components/Footer'
import axios from 'axios'
import { serverUrl } from '../../Config/api'

const title = "Error"


const { Option } = Select
const { Dragger } = Upload

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

const props = {
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    listType: 'picture',
    accept: "image/*",
};

class Account extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            fileList: [],
            disableUpload: false,
            userGroup: [],
            disable: false,
            loading: false
        }
    }

    async componentDidMount() {
        const { user, form, loginUser } = this.props
        const { fileList } = this.state
        const { setFieldsValue } = form
        let image = {}
        if (!user) {
            this.props.history.replace('/login')
        }
        else if (user && user.avatar) {
            image.name = `${user.avatar.public_id}.${user.avatar.format}`
            image.thumbUrl = user.avatar.url
            image.uid = user._id
            fileList.push(image)
            this.setState({ fileList })
        }
        axios.get(`${serverUrl}/consumer/get/get-user/${user._id}`)
            .then((res) => {
                const { data } = res
                loginUser(data.user)
            })
            .catch((e) => console.log(e))
        setFieldsValue({ ...user, avatar: image, postalCode: user.postalCode?.join('') })
        await axios.get(`${serverUrl}/user/get-usergroup/${user.country}`)
            .then((result) => {
                const { data } = result
                if (data.success) {
                    let userGroup = []
                    for (var i of data.data) {
                        if (i.cuSwitch) {
                            userGroup.push(i)
                        }
                    }
                    this.setState({ userGroup })
                }
            })
    }

    normFile = e => {
        this.setState({ disableUpload: false })
        if (e.file.type && e.file.type.indexOf('image')) {
            // this.openNotification(title, 'Please Upload an Image', 'close-circle', 'red')
            return
        }
        if (Array.isArray(e)) {
            return e;
        }
        if (e.fileList.length) {
            this.setState({ disableUpload: true, fileList: e.fileList })
        }
        return e && e.fileList;
    }

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    handleSubmit = e => {
        const { user, form } = this.props
        const { validateFields } = form
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                this.setState({ disable: true, loading: true })
                let formData = new FormData();
                for (var i in values) {
                    if (values[i]) {
                        formData.append(i, values[i])
                    }
                }
                formData.append('_id', user._id)
                if (values.avatar.file) {
                    formData.set('avatar', values.avatar.fileList[0].originFileObj)
                }
                else {
                    formData.delete('avatar')
                }
                formData.set('postalCode', JSON.stringify(values.postalCode.split(/([0-9]+)/).filter(Boolean)))
                axios.post(`${serverUrl}/consumer/user/update-account`, formData)
                    .then((result) => {
                        const { data } = result
                        if (data.success) {
                            this.openNotification('Success', data.message, 'check')
                            this.props.loginUser(data.data)
                            setTimeout(() => {
                                this.setState({ disable: false, loading: false })
                                this.props.history.replace('/')
                            }, 500)
                        }
                        else {
                            this.openNotification(title, data.message, 'close-circle', 'red')
                            this.setState({ disable: false, loading: false })
                        }
                    })
                    .catch((err) => {
                        this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                        this.setState({ disable: false, loading: false })
                    })
            }
        });
    }

    validateZip = (rule, value, callback) => {
        const { form } = this.props;
        var rege = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
        if (!rege.test(form.getFieldValue('postalCode'))) {
            callback('Please Enter Valid ZipCode!');
        } else {
            callback();
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { disableUpload, userGroup, disable, loading } = this.state
        return (
            <div>
                <div className="advertisement2">
                </div>


                <div className="header bg-light">
                    <header className="header bg-light">
                        <nav className="navbar  navbar-light">
                            <div className="container">
                                <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="collapsingNavbar">
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item"><Link className="nav-link" to="/">Home</Link> </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Settings
                                                </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <Link className="dropdown-item" to="/pantry-setting">Pantry settings </Link>
                                                <Link className="dropdown-item" to="/brochure-setting">Brochure settings</Link>
                                                <Link className="dropdown-item" to="/shopping-setting">ShoppingList settings</Link>
                                                <Link className="dropdown-item" to="/1to1-setting">1 To 1 Offer settings</Link>
                                                <Link className="dropdown-item" to="/notification-setting">Notifications settings</Link>
                                            </div>
                                        </li>
                                        <li className="nav-item"><Link className="nav-link" to="/pantry">Pantry</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/brochure">Brochure</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/shoppinglist">ShoppingList</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/1to1">1 To 1 Offer</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </header>
                </div>

                <section className="main-content-bg2">
                    <div className="center1">
                        <div className="card2">
                            <Form
                                className="login-form form-w"
                                hideRequiredMark={true}
                                onSubmit={this.handleSubmit}
                                encType="multipart/form-data">
                                <h1 className="heading1" >ACCOUNT SETTINGS</h1>
                                <Form.Item
                                    label="User Name"
                                >
                                    {getFieldDecorator('userName', {
                                        rules: [{ required: true, message: 'Please input Name!' }],
                                    })(
                                        <Input
                                            type="text"
                                            minLength={3}
                                            placeholder="Name"
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Email"
                                >
                                    {getFieldDecorator('email', {
                                        rules: [{ required: true, message: 'Please input Email!' }],
                                    })(
                                        <Input
                                            type="email"
                                            minLength={3}
                                            placeholder="Email"
                                            disabled
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Surname"
                                >
                                    {getFieldDecorator('surName', {
                                        rules: [{ message: 'Please input Surname Name!' }],
                                    })(
                                        <Input
                                            type="text"
                                            minLength={3}
                                            placeholder="Surname"
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Telno"
                                >
                                    {getFieldDecorator('telNo', {
                                        rules: [{ message: 'Please input Telno!!' }],
                                    })(
                                        <Input
                                            type="number"
                                            minLength={3}
                                            placeholder="Telno"
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Postal Code"
                                >
                                    {getFieldDecorator('postalCode', {
                                        rules: [
                                            {
                                                validator: this.validateZip,
                                            }
                                        ],
                                    })(
                                        <Input
                                            type="text"
                                            minLength={3}
                                            placeholder="Postal Code"
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="House No"
                                >
                                    {getFieldDecorator('houseNo')(
                                        <Input
                                            type="number"
                                            placeholder="House No"
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="House No extra info"
                                >
                                    {getFieldDecorator('houseNoExtra', {
                                        rules: [{ message: 'Please input House No extra info!' }],
                                    })(
                                        <Input type="text" placeholder="House No extra info!!!" />,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="City"
                                >
                                    {getFieldDecorator('city', {
                                        rules: [{ message: 'Please input City!' }],
                                    })(
                                        <Input type="text" placeholder="City!!!" />,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="User Group"
                                >
                                    {getFieldDecorator('userGroup', {
                                        rules: [{ required: true, message: 'Please Select User Group!' }],
                                    })(
                                        <Select
                                            showSearch
                                            showArrow
                                            style={{ backgroundColor: '#fff' }}
                                            placeholder="Select User Group"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                userGroup.map((v, i) => {
                                                    return <Option value={v.name} key={i}>{v.name}</Option>
                                                })
                                            }
                                        </Select>,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Upload Image"
                                >
                                    {getFieldDecorator('avatar', {
                                        rules: [{ required: true, message: 'Please upload Image!' }],
                                    })(
                                        <Upload
                                            {...props}
                                            fileList={this.state.fileList}
                                            onRemove={() => this.setState({ fileList: [] })}
                                            onChange={this.normFile}
                                            beforeUpload={beforeUpload}
                                        >
                                            <Button disabled={this.state.fileList.length ? true : false}>
                                                <Icon type="upload" /> Upload Avatar
                                                </Button>
                                        </Upload>,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Country"
                                >
                                    {getFieldDecorator('country', {
                                        rules: [{ message: 'Please input Country!' }],
                                    })(
                                        <Input type="text" placeholder="Country!!!" disabled />,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Street Address"
                                >
                                    {getFieldDecorator('streetAddress', {
                                        rules: [{ message: 'Please input Street Address!' }],
                                    })(
                                        <Input type="text" placeholder="Street Address!!!" disabled />,
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <div className="submit-btn">
                                        <Button disabled={disable} loading={loading} htmlType="submit" className="btn" style={{ backgroundColor: '#0587EF', color: 'white', fontWeight: 'bold', fontSize: 14, height: 50, display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                                            Save
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                            <br />

                            {/* <Form
                                className="login-form"
                                hideRequiredMark={true}
                                encType="multipart/form-data">
                                <h1 className="heading1" >CHANGE PASSWORD</h1>
                                <Form.Item
                                    label="Current Password"
                                >
                                    {getFieldDecorator('curentPass', {
                                        rules: [{ required: true, message: 'Please input Current Password!' }],
                                    })(
                                        <Input.Password placeholder="Current Password" />,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="New Password"
                                >
                                    {getFieldDecorator('newPass', {
                                        rules: [{ required: true, message: 'Please New Password!' }],
                                    })(
                                        <Input.Password placeholder="New Password!!!" />,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Confirm"
                                >
                                    {getFieldDecorator('confirmPass', {
                                        rules: [{ required: true, message: 'Please input Password!' }],
                                    })(
                                        <Input.Password placeholder="Confirm New Password!!!" />,
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <div className="submit-btn">
                                        <Button htmlType="submit" className="btn" style={{ backgroundColor: '#0587EF', color: 'white', fontWeight: 'bold', fontSize: 14, height: 50, display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                                            Adjust
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form> */}
                        </div>
                    </div>
                </section>



                <Footer {...this.props} />
            </div>
        )
    }
}


const AccountForm = Form.create({ name: 'normal_login' })(Account);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(AccountForm)