import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser, promotionAds } from '../../Redux/actions/authActions'
import logo from '../../assets/pantrylogo.png'
import { Form, Icon, message, Button, notification, Card, Popover, List, Collapse, Tabs, Checkbox, Skeleton } from 'antd';
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import Footer from '../../Components/Footer'
import moment from 'moment'
import { serverUrl } from '../../Config/api'

const { Panel } = Collapse

var title = "Error"

const { TabPane } = Tabs

function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2")
}

class OneToOne extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disable: false,
            visible: false,
            questionsData: [],
            isData: false,
            loadData: true,
            allTopics: [],
            orderOfData: [],
            allData: [],
            groceryData: [],
            selectedData: [],
            currentTopic: [],
            isAds: true,
            loadShopping: false
        }
    }

    async componentDidMount() {
        const { user, promotionAds } = this.props
        let { currentTopic } = this.state
        if (!user) {
            return this.props.history.replace('/login')
        }
        axios.get(`${serverUrl}/admin/get/get-promotion-by-group/${user.userGroup}`)
            .then((res) => {
                const { data, success } = res.data
                if (success) {
                    promotionAds(data)
                    if (!data.length) {
                        this.setState({ isAds: false })
                    }
                }
            })
            .catch((e) => console.log(e))

        axios.get(`${serverUrl}/consumer/get/get-user/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    loginUser(data.user)
                }
                else {
                    return this.props.history.replace('/login')
                }
            })
            .catch((e) => console.log(e))
        await axios.get(`${serverUrl}/consumer/get/1to1-data/${user._id}`)
            .then((res) => {
                let { success, data } = res.data
                var allTopics = []
                var ids = []
                if (success) {
                    for (var i in data) {
                        var { name, _id } = data[i].topic
                        if (ids.indexOf(_id) === -1) {
                            ids.push(_id)
                            allTopics.push({
                                topicId: _id,
                                topicName: name
                            })
                        }
                    }
                    this.setState({ allTopics }, () => {
                        const { allTopics, orderOfData } = this.state
                        for (var i in data) {
                            if (allTopics[0].topicId === data[i].topic._id) {
                                currentTopic = data[i].topic
                                orderOfData.push(data[i])
                            }
                        }

                        var groc = []
                        var groceryData = []
                        var topic = allTopics[0]


                        for (var j in data) {
                            if (topic.topicId === data[j].topic._id) {
                                groc.push(data[j])
                            }
                        }

                        for (var i in groc) {
                            var obj = {
                                title: groc[i].groceryName,
                                value: i,
                                key: i,
                                children: []
                            }
                            var { excelFileObj } = groc[i]
                            var count = 0
                            for (var j in excelFileObj) {
                                if (j !== "null") {
                                    obj.children.push({
                                        title: j,
                                        value: `${i}-${count}`,
                                        key: `${i}-${count}`,
                                        children: []
                                    })
                                    var childCount = 0
                                    for (var k in excelFileObj[j]) {
                                        if (k !== "null") {
                                            obj.children[count].children.push({
                                                title: k,
                                                value: `${i}-${count}-${childCount}`,
                                                key: `${i}-${count}-${childCount}`,
                                                children: []
                                            })
                                            for (var l in excelFileObj[j][k]) {
                                                obj.children[count].children[childCount].children.push({
                                                    title: excelFileObj[j][k][l]['product_title'],
                                                    value: `${i}-${count}-${childCount}-${l}`,
                                                    key: `${i}-${count}-${childCount}-${l}`,
                                                    data: excelFileObj[j][k][l]
                                                })
                                            }
                                            childCount++
                                        }
                                    }
                                    count++
                                }
                            }
                            groceryData.push(obj)
                        }
                        this.setState({ groceryData, groceryArr: groc, topic, orderOfData, isData: true, loadData: false, allData: data, currentTopic })
                    })
                }
                else {
                    this.setState({ loadData: false })
                }
            })
    }

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    linkMenuTop(item) {
        return (
            <Collapse accordion style={{ maxWidth: 200 }}>
                <Panel header="Omschrijving " key="1">
                    <p>{item['additional_product_description']}</p>
                </Panel>
                <Panel header="Ingrediënten" key="2">
                    <p>{Number.isNaN(item['product_ingredients'] * 100) ? item['product_ingredients'] : `${item['product_ingredients'] * 100}%`}</p>
                </Panel>
                <Panel header="Voedingswaarde" key="3">
                    <p>{item['Nutritional Values']}</p>
                </Panel>
                {item['allergy_information'] ? <Panel header="Allergie info" key="4">
                    <p>{item['allergy_information']}</p>
                </Panel> : null}
            </Collapse>
        )
    }

    updateOrderOfProducts = e => {
        let { allTopics, allData, currentTopic } = this.state
        var groc = []
        var groceryData = []
        var topic = allTopics[e]


        for (var j in allData) {
            if (topic.topicId === allData[j].topic._id) {
                currentTopic = allData[j].topic
                groc.push(allData[j])
            }
        }

        for (var i in groc) {
            var obj = {
                title: groc[i].groceryName,
                value: i,
                key: i,
                children: []
            }
            var { excelFileObj } = groc[i]
            var count = 0
            for (var j in excelFileObj) {
                if (j !== "null") {
                    obj.children.push({
                        title: j,
                        value: `${i}-${count}`,
                        key: `${i}-${count}`,
                        children: []
                    })
                    var childCount = 0
                    for (var k in excelFileObj[j]) {
                        if (k !== "null") {
                            obj.children[count].children.push({
                                title: k,
                                value: `${i}-${count}-${childCount}`,
                                key: `${i}-${count}-${childCount}`,
                                children: []
                            })
                            for (var l in excelFileObj[j][k]) {
                                obj.children[count].children[childCount].children.push({
                                    title: excelFileObj[j][k][l]['product_title'],
                                    value: `${i}-${count}-${childCount}-${l}`,
                                    key: `${i}-${count}-${childCount}-${l}`,
                                    data: excelFileObj[j][k][l]
                                })
                            }
                            childCount++
                        }
                    }
                    count++
                }
            }
            groceryData.push(obj)
        }
        this.setState({ groceryData, groceryArr: groc, currentTopic, topic })
    }

    updateSelect(e) {
        let { selectedData } = this.state
        if (selectedData.indexOf(e) === -1) {
            selectedData.push(e)
        }
        else {
            selectedData = selectedData.filter(v => v !== e)
        }
        this.setState({ selectedData })
    }

    handleSubmit = e => {
        const { user } = this.props
        const { currentTopic, selectedData } = this.state
        if (!selectedData.length) {
            return message.warning('Please Select Items First')
        }
        this.setState({ loading: true })
        var values = {}
        values.userId = user._id
        values.country = user.country
        values.topic = currentTopic
        values.topicId = currentTopic._id
        values.topicName = currentTopic.name
        values.orderOfProducts = []
        axios.post(`${serverUrl}/consumer/post/shopping-by-topic`, {
            id: user._id,
            topicId: values.topicId
        })
            .then((result) => {
                const { data } = result

                if (data.success) {
                    values.orderOfProducts = [...data['result']['orderOfProducts'], ...selectedData]
                    values.orderOfProducts = [...new Set(values.orderOfProducts)]
                    values.orderOfData = this.updateChild(values.orderOfProducts)
                    values.shareShopping = data['result']['shareShopping']
                    values.showAlternative = data['result']['showAlternative']
                    values.shareShopping = data['result']['shareShopping']
                    if (data['result']['shareShopping']) {
                        values.authorizeFully = data['result']['authorizeFully']
                        values.authorizeSuggest = data['result']['authorizeSuggest']
                    }
                    this.handleUpdateShopping(values)
                }
                else {
                    values.orderOfProducts = selectedData
                    values.orderOfData = this.updateChild(values.orderOfProducts)
                    values.showShopping = false
                    values.showAlternative = false
                    values.shareShopping = false
                    this.handleUpdateShopping(values)
                }

            })
    };

    updateChild = e => {
        const { groceryData } = this.state
        let myData = []
        for (var v of e) {
            for (var i of groceryData) {
                if (i.value === v.charAt(0)) {
                    var data = myData.filter(d => d.value === v.charAt(0))
                    if (!data.length) {
                        myData.push({
                            title: i.title,
                            value: i.value,
                            key: i.key,
                            children: []
                        })
                    }
                    const { children } = i
                    for (var j of children) {
                        if (j.value === v.slice(0, 3)) {
                            var data1 = myData.map((d, index) => {
                                if (d.value === v.charAt(0)) {
                                    return index
                                }
                            })
                            var key = data1.filter(d => d !== undefined)[0]
                            var productGroup = myData[key].children.filter(d => d.value === j.value)
                            if (!productGroup.length) {
                                myData[key].children.push({
                                    title: j.title,
                                    value: j.value,
                                    key: j.key,
                                    children: []
                                })
                            }
                            var groupChild = j.children
                            for (var k of groupChild) {
                                if (k.value === v.slice(0, 5)) {
                                    var data2 = myData[key].children.map((d, ind) => {
                                        if (d.value === v.slice(0, 3)) {
                                            return ind
                                        }
                                    })
                                    var key1 = data2.filter(d => d !== undefined)[0]
                                    var productType = myData[key].children[key1].children.filter(d => d.value === k.value)
                                    if (!productType.length) {
                                        myData[key].children[key1].children.push({
                                            title: k.title,
                                            value: k.value,
                                            key: k.key,
                                            children: []
                                        })
                                    }
                                    var titleChild = k.children
                                    for (var l of titleChild) {
                                        if (l.value === v) {
                                            var data3 = myData[key].children[key1].children.map((d, ind) => {
                                                if (d.value === v.slice(0, 5)) {
                                                    return ind
                                                }
                                            })

                                            var key2 = data3.filter(d => d !== undefined)[0]
                                            var productTitle = myData[key].children[key1].children[key2].children.filter(d => d.value === k.value)
                                            if (!productTitle.length) {
                                                myData[key].children[key1].children[key2].children.push({
                                                    title: l.title,
                                                    value: l.value,
                                                    key: l.key,
                                                    data: l.data
                                                })
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }

        return myData
    }

    handleUpdateShopping = values => {
        axios.post(`${serverUrl}/consumer/post/add-shoppinglist`, values)
            .then((result) => {
                if (result.data.success) {
                    this.openNotification('Success', result.data.message, 'check')
                    this.setState({ loading: false })
                    setTimeout(() => {
                        window.location.reload()
                    }, 300)
                }
                else {
                    this.openNotification(title, result.data.message, 'close-circle', 'red')
                    this.setState({ loading: false, textValue: 'Update' })
                }
            })
            .catch((err) => {
                this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                this.setState({ loading: false, textValue: 'Update' })
            })
    }

    render() {
        let { loading, groceryData, isData, loadData, allTopics, isAds } = this.state;

        const { ads, user } = this.props

        if (ads?.length) {
            var item = ads[Math.floor(Math.random() * ads.length)];
        }

        return (
            <div>
                <div className="header bg-light">
                    <header className="header bg-light">
                        <nav className="navbar  navbar-light">
                            <div className="container">
                                <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                                <h1 className="show-window">1 TO 1 OFFERS</h1>
                                <h4 className="show-window">Savings: € {roundToTwo(user.savings)}</h4>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <h1 className="show-mobile">1 TO 1 OFFERS</h1>
                                <h4 className="show-mobile">Savings: € {roundToTwo(user.savings)}</h4>

                                <div className="collapse navbar-collapse" id="collapsingNavbar">
                                    <ul className="navbar-nav ml-auto">

                                        <li className="nav-item"><Link className="nav-link" to="/">Home</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Settings
                                                </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <Link className="dropdown-item" to="/pantry-setting">Pantry settings </Link>
                                                <Link className="dropdown-item" to="/brochure-setting">Brochure settings</Link>
                                                <Link className="dropdown-item" to="/shopping-setting">ShoppingList settings</Link>
                                                <Link className="dropdown-item" to="/1to1-setting">1 To 1 Offer settings</Link>
                                                <Link className="dropdown-item" to="/notification-setting">Notifcations settings</Link>
                                            </div>
                                        </li>
                                        <li className="nav-item"><Link className="nav-link" to="/pantry">Pantry</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/brochure">Brochure</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/shoppinglist">ShoppingList</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </header>
                </div>
                <div className="main-content-bg2">
                    <section style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="card3" style={{ marginTop: 250, marginBottom: 50 }}>
                            {isData && !loadData ? <div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Tabs onTabClick={this.updateOrderOfProducts} defaultActiveKey="0" tabPosition={"top"} className="tabs">
                                        {allTopics.map((a, i) => (
                                            <TabPane tab={a.topicName} key={i}>
                                                <div style={{ display: 'flex', justifyContent: 'center', margin: 20 }}>
                                                    <Button type="dashed" onClick={this.handleSubmit} loading={loading} style={{ marginLeft: 5, marginRight: 5 }}>ShoppingList</Button>
                                                    {/* <Popconfirm
                                                        title="Are you sure delete these selected list?"
                                                        onConfirm={this.deleteItems}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button type="danger" style={{ marginLeft: 5, marginRight: 5 }} loading={loading}>Delete</Button>
                                                    </Popconfirm> */}
                                                </div>
                                                <div style={{ minHeight: 500, maxHeight: 800, overflowY: 'scroll' }}>
                                                    {groceryData.map((v, i) => {
                                                        return <div key={i} style={{ borderTop: '1px solid black' }}>
                                                            {/* <div className="list-head">
                                                                <p className="set-size" style={{ flex: 1, borderRight: '1px solid black' }}>{v.title}</p>
                                                            </div> */}
                                                            {v.children.map((y, j) => {
                                                                return y.children.map((z, k) => {
                                                                    return <div key={`${i}-${j}-${k}`}>
                                                                        <div>
                                                                            <List
                                                                                itemLayout="horizontal"
                                                                                dataSource={z.children}
                                                                                renderItem={(item, key) => (
                                                                                    <List.Item
                                                                                        style={{ display: 'flex', justifyContent: 'center' }}
                                                                                    >
                                                                                        <div className="card all-cards">
                                                                                            <div>
                                                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                                                                                    <p className="card-head">{v.title}</p>
                                                                                                    <p className="card-head">{item['data']['product_type']}</p>
                                                                                                </div>
                                                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                                                                                    <Checkbox style={{ paddingTop: 5, paddingBottom: 5, flex: 1 }} className="card-head card-top" onClick={() => this.updateSelect(item.value)}>
                                                                                                    </Checkbox>
                                                                                                    <p className="card-head card-top" style={{ flex: 4 }}>{item['data']['product_title']}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <Popover placement="leftTop" content={this.linkMenuTop(item['data'])} trigger="click">
                                                                                                <FontAwesomeIcon icon={faEllipsisV} style={{ position: 'absolute', right: 20, top: 100, fontSize: 25 }} />
                                                                                            </Popover>
                                                                                            <img src={item['data']['image_link']} alt="Avatar" style={{ height: 250, width: '100%', backgroundRepeat: 'no-repeat' }} />
                                                                                            <div className="card-bottom" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                                                <p className="card-foot">€ {item['data']['current_price']}</p>
                                                                                                <p className="card-foot">{item['data']['unit_sold']}</p>
                                                                                            </div>
                                                                                            {item['data']['orange_block_info/promoText'] ? <div>
                                                                                                <div className="card-bottom" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                                    <p className="card-head">{item['data']['promotion_period'] ? item['data']['promotion_period'] : 'Niet in aanbieding'}</p>
                                                                                                    <p className="card-head mid1">{item['data']['orange_block_info/promoText']}</p>
                                                                                                </div>
                                                                                                <div className="card-bottom" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                                    <p className="card-head">{`Normale prijs: € ${item['data']['old_price']} ${item['data']['unit_sold']}`}</p>
                                                                                                </div>
                                                                                            </div> : null}
                                                                                        </div>
                                                                                    </List.Item>
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                })
                                                            })}
                                                        </div>
                                                    })}
                                                </div>
                                            </TabPane>
                                        ))}
                                    </Tabs>
                                </div>
                            </div> : !isData && loadData && isAds && ads?.length ? <div style={{ width: '100%' }}>
                                <h2 className="ads-show">{item.name}</h2>
                                <h4 className="ads-show">{item.description}</h4>
                                <img src={item.image.url} className="image-ads" />
                                <h4 className="ads-show1">{moment(item.startDate).format('LL')} to {moment(item.endDate).format('LL')}</h4>
                            </div> : !isData && loadData ? <Skeleton paragraph={{ rows: 10 }} active /> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 200, paddingBottom: 200 }}>
                                <h1>OOPS No One To One Offers Found!!!</h1>
                            </div>}
                        </div>
                    </section>

                    <Footer {...this.props} />
                </div>
            </div>
        )
    }
}

const OneToOneForm = Form.create({ name: 'normal_login' })(OneToOne);


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        ads: state.authReducer.ads,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
        promotionAds: (ads) => dispatch(promotionAds(ads)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(OneToOneForm)