import React from 'react';
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { connect } from 'react-redux';
import { Modal, Button, message, Form, TreeSelect } from 'antd';
import SubmitButton from '../SubmitButton'


class SearchComp extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disable: false,
            show: true,
            role: this.props.role
        }
    }


    handleOk = () => {
        this.setState({ loading: true });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    render() {
        const { loading } = this.state
        const { visible, disableModal, form, orderOfData } = this.props
        const { getFieldDecorator } = form;
        return (
            <div>
                <div>
                    <Modal
                        visible={visible}
                        title="Pantry Search"
                        onCancel={disableModal}
                        footer={[
                            <Button key="back" onClick={disableModal}>
                                Close
                            </Button>
                        ]}
                    >
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            <Form
                                className="login-form form-w"
                                style={{ width: 300 }}
                                hideRequiredMark={true}
                                onSubmit={this.handleSubmit}
                                encType="multipart/form-data">
                                <h1 className="heading1" >Pantry Search</h1>
                                <Form.Item
                                    label="Order of Products"
                                >
                                    {getFieldDecorator('orderOfProducts', {
                                        rules: [{ required: true, message: 'Please Select products!' }],
                                    })(
                                        <TreeSelect
                                            treeData={orderOfData.length ? orderOfData : null}
                                            placeholder="Select Products in order you want to display!!!"
                                            // treeCheckable={true}
                                            // allowClear={true}
                                            showArrow={true}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto', width: 400 }}
                                            onChange={this.updateChild}
                                            style={{
                                                width: '100%'
                                            }}
                                        />,
                                    )}
                                </Form.Item>
                            </Form>
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}



const mapStateToProps = (state) => {

    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
        removeUser: () => dispatch(removeUser())
    }
}

const SearchCompForm = Form.create({ name: 'normal_login' })(SearchComp);

export default connect(mapStateToProps, mapDispatchToProps)(SearchCompForm)