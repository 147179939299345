import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Form, Icon, Input, Button, Select, notification } from 'antd';
import { loginUser } from '../../Redux/actions/authActions'
import logo from '../../assets/pantrylogo.png'
import Footer from '../../Components/Footer'
import axios from 'axios'
import country from '../../country'
import { serverUrl } from '../../Config/api'

const title = "Error"

const { Option } = Select


class Register extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disable: false
        }
    }

    handleSubmit = e => {
        const { validateFields } = this.props.form
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                this.setState({ disable: true, loading: true })
                values.postalCode = values.postalCode.split(/([0-9]+)/).filter(Boolean)
                axios.post(`${serverUrl}/consumer/user/signup`, values)
                    .then((result) => {
                        const { data } = result
                        if (data.success) {
                            this.openNotification('Success', data.message, 'check')
                            this.props.loginUser(data.data)
                            setTimeout(() => {
                                this.setState({ disable: false, loading: false })
                                this.props.history.replace('/account')
                            })
                        }
                        else {
                            this.openNotification(title, data.message, 'close-circle', 'red')
                            this.setState({ disable: false, loading: false })
                        }
                    })
                    .catch((err) => {
                        this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                        this.setState({ disable: false, loading: false })
                    })
            }
        });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    validateZip = (rule, value, callback) => {
        const { form } = this.props;
        var rege = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
        if (!rege.test(form.getFieldValue('postalCode'))) {
            callback('Please Enter Valid ZipCode!');
        } else {
            callback();
        }
    }

    render() {
        const { loading, disable } = this.state
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <div className="advertisement">
                </div>
                <header className="header1 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <a className="navbar-brand" href="#"><img src={logo} alt="logo" className="img-fluid" /></a>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">
                            </div>
                        </div>
                    </nav>
                </header>
                <div className="main-content-bg">
                    <div id="contact" />
                    <section className="contact">
                        <div className="center1">
                            <div className="card2">
                                <Form
                                    className="login-form form-w"
                                    hideRequiredMark={true}
                                    onSubmit={this.handleSubmit}
                                    encType="multipart/form-data">
                                    <h1 className="heading1" >Register</h1>
                                    <Form.Item
                                        label="Username"
                                    >
                                        {getFieldDecorator('userName', {
                                            rules: [{ required: true, message: 'Please input Username!' }],
                                        })(
                                            <Input
                                                type="text"
                                                placeholder="Username"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Email"
                                    >
                                        {getFieldDecorator('email', {
                                            rules: [{ required: true, message: 'Please input Email!' }],
                                        })(
                                            <Input
                                                type="email"
                                                placeholder="Email"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Password"
                                    >
                                        {getFieldDecorator('password', {
                                            rules: [{ required: true, message: 'Please input Password!' }],
                                        })(
                                            <Input.Password
                                                minLength={9}
                                                placeholder="Password (minimum of 9 characters)"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Confirm Password"
                                    >
                                        {getFieldDecorator('confirmPass', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please Confirm your Password!'
                                                },
                                                {
                                                    validator: this.compareToFirstPassword,
                                                }
                                            ],
                                        })(
                                            <Input.Password
                                                minLength={9}
                                                placeholder="Confirm Password"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Country"
                                    >
                                        {getFieldDecorator('country', {
                                            rules: [{ required: true, message: 'Please Select Country!' }],
                                        })(
                                            <Select
                                                showSearch
                                                style={{ backgroundColor: '#fff' }}
                                                placeholder="Select Country"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    Object.entries(country).map((v, i) => {
                                                        return <Option value={v[0]} key={i}>{v[0]}</Option>
                                                    })
                                                }
                                            </Select>,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Street Address"
                                    >
                                        {getFieldDecorator('streetAddress', {
                                            rules: [{ required: true, message: 'Please input Street Address!' }],
                                        })(
                                            <Input
                                                type="text"
                                                placeholder="Street Address"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Postal Code"
                                    >
                                        {getFieldDecorator('postalCode', {
                                            rules: [
                                                {
                                                    validator: this.validateZip,
                                                }
                                            ],
                                        })(
                                            <Input
                                                type="text"
                                                minLength={3}
                                                placeholder="Postal Code"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="submit-btn">
                                            <Button disabled={disable} loading={loading} htmlType="submit" className="btn" style={{ backgroundColor: '#0587EF', color: 'white', fontWeight: 'bold', fontSize: 14, height: 50, display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                                                SignUp
                                        </Button>
                                        </div>
                                        <div className="link-f">
                                            Already have account?&nbsp;&nbsp;<Link to="/login" className="login-form-forgot" style={{ color: '#1abc9c' }}>Login</Link>
                                            <Link to="/forgot" className="login-form-forgot" style={{ color: '#1abc9c', marginLeft: 20 }}>Forgot password</Link>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer {...this.props} />
            </div>
        )
    }
}

const RegisterForm = Form.create({ name: 'normal_login' })(Register);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm)