import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../../Redux/actions/authActions'
import logo from '../../assets/pantrylogo.png'
import { Form, Icon, notification, Switch, Checkbox } from 'antd';
import axios from 'axios'
import Footer from '../../Components/Footer'
import SubmitButton from '../../Components/SubmitButton'
import { serverUrl } from '../../Config/api'

const title = "Error"

const CheckboxGroup = Checkbox.Group;

class NotificationsSetting extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disable: false,
            visible: false,
            questionsData: [],
            offer1to1: true,
            brochure: true

        }
    }

    async componentDidMount() {
        const { user, form } = this.props
        if (!user) {
            this.props.history.replace('/login')
        }
        const { setFieldsValue } = form
        await axios.get(`${serverUrl}/consumer/get/notification/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    const { result } = data
                    this.setState({ offer1to1: result.offer1to1, brochure: result.brochure })
                    setFieldsValue(result)
                }
            })
    }

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    handleSubmit = e => {
        const { user, form } = this.props
        const { validateFields } = form
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                this.setState({ disable: true, loading: true })
                values.userId = user._id
                values.country = user.country
                axios.post(`${serverUrl}/consumer/post/add-notification`, values)
                    .then((result) => {
                        if (result.data.success) {
                            this.openNotification('Success', result.data.message, 'check')
                            this.setState({ disable: false, loading: false })
                            setTimeout(() => {
                                window.location.reload()
                            }, 300)
                        }
                        else {
                            this.openNotification(title, result.data.message, 'close-circle', 'red')
                            this.setState({ disable: false, loading: false })
                        }
                    })
                    .catch((err) => {
                        this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                        this.setState({ disable: false, loading: false })
                    })
            }
        });
    };



    checkLimit(name) {
        const { getFieldValue, setFieldsValue } = this.props.form
        if (getFieldValue(name).length > 4) {
            this.openNotification('Error', 'You can only select Maximun 4!!!', 'close-circle', 'red')
            setFieldsValue({
                [name]: getFieldValue(name).slice(0, 4)
            })

            return false
        }

        return true
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let { loading, disable, offer1to1, brochure } = this.state;

        return (
            <div>
                <div className="advertisement2">
                </div>

                <div className="header bg-light">
                    <header className="header bg-light">
                        <nav className="navbar  navbar-light">
                            <div className="container">
                                <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="collapsingNavbar">
                                    <ul className="navbar-nav ml-auto">

                                        <li className="nav-item"><Link className="nav-link" to="/">Home</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Settings
                                                </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <Link className="dropdown-item" to="/pantry-setting">Pantry settings </Link>
                                                <Link className="dropdown-item" to="/brochure-setting">Brochure settings</Link>
                                                <Link className="dropdown-item" to="/shopping-setting">ShoppingList settings</Link>
                                                <Link className="dropdown-item" to="/1to1-setting">1 To 1 Offer settings</Link>
                                            </div>
                                        </li>
                                        <li className="nav-item"><Link className="nav-link" to="/pantry">Pantry</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/brochure">Brochure</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/shoppinglist">ShoppingList</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/1to1">1 To 1 Offer</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </header>
                </div>
                <div className="main-content-bg2">
                    <section>

                        <div className="center1">
                            <div className="card2">
                                <Form
                                    className="login-form form-w"
                                    hideRequiredMark={true}
                                    onSubmit={this.handleSubmit}
                                    encType="multipart/form-data">
                                    <h1 className="heading1" >Notifications Settings</h1>
                                    <Form.Item
                                        label="1 to 1 Offers"
                                    >
                                        {getFieldDecorator('offer1to1', {
                                            rules: [{ required: true, message: 'Please Select 1 to 1 Offers!' }],
                                        })(
                                            <Switch checked={offer1to1} onChange={e => this.setState({ offer1to1: e })} />,
                                        )}
                                    </Form.Item>
                                    {offer1to1 ? <Form.Item
                                        label="Select Communication"
                                    >
                                        {getFieldDecorator('communication1to1', {
                                            rules: [{ required: true, message: 'Please Select Select Communication!' }],
                                        })(
                                            <CheckboxGroup
                                                options={['Chat', 'Email']}
                                            />,
                                        )}
                                    </Form.Item> : null}
                                    <Form.Item
                                        label="Personalised Brochure"
                                    >
                                        {getFieldDecorator('brochure', {
                                            rules: [{ required: true, message: 'Please Select Personalised Brochure!' }],
                                        })(
                                            <Switch checked={brochure} onChange={e => this.setState({ brochure: e })} />,
                                        )}
                                    </Form.Item>
                                    {brochure ? <Form.Item
                                        label="Select Communication"
                                    >
                                        {getFieldDecorator('brochureCommunication', {
                                            rules: [{ required: true, message: 'Please Select Select Communication!' }],
                                        })(
                                            <CheckboxGroup
                                                options={['Chat', 'Email']}
                                            />,
                                        )}
                                    </Form.Item> : null}
                                    <Form.Item>
                                        <SubmitButton loading={loading} disable={disable} />
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </section>

                    <Footer {...this.props} />
                </div>
            </div>
        )
    }
}

const NotificationsSettingForm = Form.create({ name: 'normal_login' })(NotificationsSetting);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(NotificationsSettingForm)