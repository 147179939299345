import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../../Redux/actions/authActions'
import logo from '../../assets/pantrylogo.png'
import { Form, Icon, Input, Button, Select, notification, Skeleton, TreeSelect, Switch } from 'antd';
import axios from 'axios'
import Footer from '../../Components/Footer'
import SubmitButton from '../../Components/SubmitButton'
import { serverUrl } from '../../Config/api'

const { Option } = Select

const title = "Error"

class OneToOneSetting extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disable: false,
            visible: false,
            showAuth: true,
            isData: true,
            allUsers: [],
            currentEmail: '',
            showPassword: false,
            userPassword: '',
            loading1: false,
            shareShopping: true,
            showAlternative: true,
            showShopping: true,
            authorizeFully: [],
            fieldValue: '',
            suggestUsers: [],
            offerTypes: [],
            show1to1: false,
            pantryProducts: true,
            shoppingList: true,
            shops: []
        }
    }

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    async componentDidMount() {
        const { user } = this.props
        if (!user) {
            this.props.history.replace('/login')
        }

        await axios.get(`${serverUrl}/admin/get/product-by-country/${user.country}`)
            .then((result) => {
                const { data, success } = result.data
                if (success) {
                    this.setState({ shops: data, isData: data.length }, async () => {
                        const { user, form } = this.props
                        const { setFieldsValue } = form
                        await axios.get(`${serverUrl}/consumer/get/1to1/${user._id}`)
                            .then((res) => {
                                const { success, result } = res.data
                                if (success) {
                                    setFieldsValue(result)
                                }
                            })

                        axios.get(`${serverUrl}/get/get-one-by-country/${user.country}`)
                            .then((res) => {
                                const { success, data } = res.data
                                if (success) {
                                    var offerTypes = [... new Set(data.map(v => v.Offertype))]
                                    this.setState({ offerTypes })
                                }
                            })
                    })
                }
            })
    }

    handleSubmit = e => {
        const { shops } = this.state
        const { user, form } = this.props
        const { validateFields } = form
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                this.setState({ disable: true, loading: true })
                values.userId = user._id
                values.country = user.country
                values.shopsData = shops.filter(v => values.shops.indexOf(v._id) !== -1)
                axios.post(`${serverUrl}/consumer/post/add-1to1`, values)
                    .then((result) => {
                        if (result.data.success) {
                            this.openNotification('Success', result.data.message, 'check')
                            this.setState({ disable: false, loading: false })
                            window.location.reload()
                        }
                        else {
                            this.openNotification(title, result.data.message, 'close-circle', 'red')
                            this.setState({ disable: false, loading: false })
                        }
                    })
                    .catch((err) => {
                        this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                        this.setState({ disable: false, loading: false })
                    })
            }
        });
    };

    checkLimit(name) {
        const { getFieldValue, setFieldsValue } = this.props.form
        if (getFieldValue(name).length > 4) {
            this.openNotification('Error', 'You can only select Maximun 4!!!', 'close-circle', 'red')
            setFieldsValue({
                [name]: getFieldValue(name).slice(0, 4)
            })

            return false
        }

        return true
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        let { loading, disable, show1to1, shops, isData, offerTypes, shoppingList, pantryProducts } = this.state;

        return (
            <div>
                <div className="advertisement2">
                </div>

                <div className="header bg-light">
                    <header className="header bg-light">
                        <nav className="navbar  navbar-light">
                            <div className="container">
                                <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="collapsingNavbar">
                                    <ul className="navbar-nav ml-auto">

                                        <li className="nav-item"><Link className="nav-link" to="/">Home</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Settings
                                                </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <Link className="dropdown-item" to="/pantry-setting">Pantry settings </Link>
                                                <Link className="dropdown-item" to="/brochure-setting">Brochure settings</Link>
                                                <Link className="dropdown-item" to="/shopping-setting">ShoppingList settings</Link>
                                                <Link className="dropdown-item" to="/notification-setting">Notifcations settings</Link>
                                            </div>
                                        </li>
                                        <li className="nav-item"><Link className="nav-link" to="/pantry">Pantry</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/brochure">Brochure</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/shoppinglist">ShoppingList</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/1to1">1 To 1 Offer</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li>
                                        <li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </header>
                </div>
                <div className="main-content-bg2">
                    <section>

                        <div className="center1">
                            <div className="card2">
                                {shops.length ?
                                    <Form
                                        className="login-form form-w"
                                        hideRequiredMark={true}
                                        onSubmit={this.handleSubmit}
                                        encType="multipart/form-data">
                                        <h1 className="heading1" >OneToOne Settings</h1>
                                        <Form.Item
                                            label="Shops"
                                        >
                                            {getFieldDecorator('shops', {
                                                rules: [{ required: true, message: 'Please Select Shops!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    showArrow
                                                    mode="multiple"
                                                    style={{ backgroundColor: '#fff' }}
                                                    placeholder="Select Shops"
                                                    optionFilterProp="children"
                                                    onSelect={() => this.checkLimit('shops')}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        shops.map((v, i) => {
                                                            return <Option value={v._id} key={i}>{v.groceryName}</Option>
                                                        })
                                                    }
                                                </Select>,
                                            )}
                                        </Form.Item>
                                        <Form.Item
                                            label="Offertype"
                                        >
                                            {getFieldDecorator('offerType', {
                                                rules: [{ required: false, message: 'Please Select Offertype!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    showArrow
                                                    mode="multiple"
                                                    style={{ backgroundColor: '#fff' }}
                                                    placeholder="Select OfferType"
                                                    optionFilterProp="children"
                                                    onSelect={() => this.checkLimit('offerType')}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        offerTypes.map((v, i) => {
                                                            return <Option value={v} key={i}>{v}</Option>
                                                        })
                                                    }
                                                </Select>,
                                            )}
                                        </Form.Item>
                                        <Form.Item>
                                            <SubmitButton loading={loading} disable={disable} />
                                        </Form.Item>
                                    </Form> : isData ? <Skeleton active paragraph={{ rows: 10 }} /> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 200, paddingBottom: 200 }}>
                                        <h1>OOPS No Data Found!!!</h1>
                                    </div>}
                            </div>
                        </div>
                    </section>

                    <Footer {...this.props} />
                </div>
            </div>
        )
    }
}

const OneToOneSettingForm = Form.create({ name: 'normal_login' })(OneToOneSetting);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(OneToOneSettingForm)